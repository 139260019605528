import { createElement, memo } from "react";
import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import Image from "next/image";
import { Emoji, Identicon, Spinner } from "~/components/atoms";
import type { ThemeType } from "@sablier/v2-themes";
import type { IImage, StaticImageData } from "@sablier/v2-types";
import type { FC, ReactNode } from "react";

interface WrapperProps {
  color?: keyof ThemeType["colors"];
  size?: number | string;
  thickness?: string | number;
}

const WrapperPartial = styled.div<WrapperProps>`
  ${(props) => props.theme.styles.row}

  & {
    position: relative;
    flex-shrink: 0;
    justify-content: center;
    width: var(--size, ${(props) => props.size || props.theme.sizes.icon});
    height: var(--size, ${(props) => props.size || props.theme.sizes.icon});
    min-width: var(--size, ${(props) => props.size || props.theme.sizes.icon});
  }
  svg {
    width: var(--size, ${(props) => props.size || props.theme.sizes.icon});
    height: var(--size, ${(props) => props.size || props.theme.sizes.icon});
    color: inherit;
    ${(props) =>
      props.color &&
      css`
        color: ${props.color && props.theme.colors[props.color]};
      `};
    ${(props) =>
      props.thickness &&
      css`
        stroke-width: ${props.thickness};
      `};
  }
  & > * {
    position: absolute;
    z-index: 2;
  }

  &[data-purpose="heroicon"] {
    svg {
      stroke-width: var(--thickness, ${(props) => props.thickness || 2.2});
    }
  }

  &[data-purpose="custom"] {
    width: auto;
    & > * {
      position: relative;
    }
  }

  ${(props) =>
    props.color &&
    css`
      &:before {
        position: absolute;
        content: "";
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
      &:not([data-purpose="spinner"]) {
        &:before {
          background-color: ${props.color && props.theme.colors[props.color]};
        }
      }

      &[data-purpose="spinner"],
      &[data-purpose="heroicon"] {
        &:before {
          display: none;
        }
      }
    `};
`;

const Imager = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  img {
    object-fit: contain;
  }
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const Empty = styled.div<{ color?: keyof ThemeType["colors"] }>`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(props) =>
    (props.color && props.theme.colors[props.color]) ||
    props.theme.colors.dark200};
`;

const Wrapper = styled(WrapperPartial)`
  ${Imager} {
    width: var(--size, ${(props) => props.size || props.theme.sizes.icon});
    height: var(--size, ${(props) => props.size || props.theme.sizes.icon});
  }
`;

export interface Props {
  className?: string;
  /** Only used for components that cannot dynamically get the currentColor from css or if force is required */
  color?: keyof ThemeType["colors"];
  purpose?:
    | "custom"
    | "emoji"
    | "empty"
    | "heroicon"
    | "identicon"
    | "image"
    | "img"
    | "spinner";
  fallback?: IImage;
  size?: number;
  thickness?: number;
  value?: IImage | JSX.Element | ReactNode | FC | keyof ThemeType["colors"];
}

function Icon({
  className,
  color,
  purpose = "heroicon",
  size,
  thickness,
  value,
}: Props) {
  return (
    <Wrapper
      className={className}
      color={color}
      data-component={"icon"}
      data-purpose={purpose}
      size={size && _.toSuffix(size, "px")}
      thickness={thickness}
    >
      {!_.isNil(value) && (
        <>
          {purpose === "custom" ? createElement(value as FC, {}) : false}
          {purpose === "emoji" ? <Emoji symbol={value as string} /> : false}
          {purpose === "heroicon" ? createElement(value as FC, {}) : false}
          {purpose === "identicon" ? (
            <Identicon address={value as string} size={size} />
          ) : (
            false
          )}
          {purpose === "image" ? (
            <Imager>
              <Image
                alt={""}
                src={value as StaticImageData}
                fill
                quality={100}
                sizes={`${size ? _.toSuffix(size, "px") : "10vw"}`}
                unoptimized
              />
            </Imager>
          ) : (
            false
          )}
          {purpose === "img" ? (
            <Imager>
              <img alt={""} src={_.toString(value)} />
            </Imager>
          ) : (
            false
          )}
        </>
      )}

      {purpose === "empty" ? (
        <Empty color={value as keyof ThemeType["colors"]} />
      ) : (
        false
      )}
      {purpose === "spinner" ? (
        <Spinner color={color} size={size} thickness={thickness} />
      ) : (
        false
      )}
    </Wrapper>
  );
}

export default memo(Icon);
