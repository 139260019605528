/** https://github.com/sablier-labs/v2-merkle-api */
const ROOT_MERKLE_API = "https://v2-services.vercel.app";
/** https://github.com/sablier-labs/merkle-tracker */
const ROOT_MERKLE_TRACKER =
  "https://merkle-tracker-production-d4bd.up.railway.app";

const services = {
  merkleAPI: {
    create: () => `${ROOT_MERKLE_API}/api/create`,
    eligibility: () => `${ROOT_MERKLE_API}/api/eligibility`,
    validity: () => `${ROOT_MERKLE_API}/api/validity`,
    health: () => `${ROOT_MERKLE_API}/api/health`,
  },
  merkleTracker: {
    claimable: (chainId: string) =>
      `${ROOT_MERKLE_TRACKER}/campaigns/${chainId}/eligible`,
    details: (chainId: string) => `${ROOT_MERKLE_TRACKER}/campaigns/${chainId}`,
    eligibility: (cid: string) =>
      `${ROOT_MERKLE_TRACKER}/campaigns/${cid}/eligibility`,
    hidden: (chainId: string) =>
      `${ROOT_MERKLE_TRACKER}/campaigns/${chainId}/hidden`,
    update: (address: string, chainId: string) =>
      `${ROOT_MERKLE_TRACKER}/campaigns/${chainId}/${address}`,
    sync: (chainId: string) =>
      `${ROOT_MERKLE_TRACKER}/campaigns/sync?chainId=${chainId}`,
  },
};

export default services;
