/**
 * Tags needed for Cypress, which can be found throughout the DOM as `data-cy` attributes.
 */
export const tags = {
  accountWarning: "account-warning",

  actionAddStream: "action-add-stream",
  actionBannerAccept: "action-banner-accept",
  actionBannerManage: "action-banner-manage",
  actionCancel: "action-cancel",
  actionCreateGroup: "action-create-group",
  actionDurationPicker: "action-duration-picker",
  actionFillIn: "action-fill-in",
  actionMomentPicker: "action-moment-picker",
  actionRenounce: "action-renounce",
  actionTokenPicker: "action-token-picker",
  actionTransfer: "action-transfer",
  actionWithdraw: "action-withdraw",
  actionAdvanced: "action-advanced",

  auth: "auth",
  banner: "banner",

  chainPicker: "chain-picker",
  coverCancel: "cover-cancel",
  coverRenounce: "cover-renounce",
  coverTransfer: "cover-transfer",
  coverWithdraw: "cover-withdraw",
  coverStreamCart: "cover-stream-cart",

  modalChainPicker: "modal-chain-picker",
  modalCountryPicker: "modal-country-picker",
  modalDurationPicker: "modal-duration-picker",
  modalMomentPicker: "modal-moment-picker",
  modalOptions: "modal-options",
  modalSearch: "modal-search",
  modalCalldata: "modal-calldata",
  modalTokenPicker: "modal-token-picker",
  modalTransaction: "modal-transaction",
  modalAirstreamBypass: "modal-airstream-bypass",
  modalAirstreamEdit: "modal-airstream-edit",
  modalAirstreamRecipients: "modal-airstream-recipients",

  pageRendered: "rendered-page",

  streamCard: "stream-card",
  streamDisplay: "stream-display",
  streamFallback: "stream-fallback",
  tab: "tab",

  airstreamFallback: "airstream-fallback",

  warning: "warning",
  warningAmount: "warning-amount",
  warningDepleted: "warning-depleted",
  warningEarly: "warning-early",
  warningExpired: "warning-expired",
  warningChain: "warning-chain",
  warningConnect: "warning-connect",
  warningProxy: "warning-proxy",
  warningToken: "warning-token",
  warningGeoblocked: "warning-geoblocked",
};
