/**
 * OFAC sanctioned addressed [December 2023]
 * https://sanctionssearch.ofac.treas.gov/Details.aspx?id=39796
 */

const banned: string[] = [
  "0x5f6c97C6AD7bdd0AE7E0Dd4ca33A4ED3fDabD4D7",
  "0xf4B067dD14e95Bab89Be928c07Cb22E3c94E0DAA",
  "0x58E8dCC13BE9780fC42E8723D8EaD4CF46943dF2",
  "0x05E0b5B40B7b66098C2161A5EE11C5740A3A7C45",
  "0x23173fE8b96A4Ad8d2E17fB83EA5dcccdCa1Ae52",
  "0x538Ab61E8A9fc1b2f93b3dd9011d662d89bE6FE6",
  "0x94Be88213a387E992Dd87DE56950a9aef34b9448",
  "0x179f48c78f57a3a78f0608cc9197b8972921d1d2",
  "0xb04E030140b30C27bcdfaafFFA98C57d80eDa7B4",
  "0x77777feddddffc19ff86db637967013e6c6a116c", // $TORN
  "0x3efa30704d2b8bbac821307230376556cf8cc39e",
  "0x746aebc06d2ae31b71ac51429a19d54e797878e9",
  "0xd90e2f925DA726b50C4Ed8D0Fb90Ad053324F31b",
  "0x6Bf694a291DF3FeC1f7e69701E3ab6c592435Ae7",
  "0x3aac1cC67c2ec5Db4eA850957b967Ba153aD6279",
  "0x723B78e67497E85279CB204544566F4dC5d2acA0",
  "0x0E3A09dDA6B20aFbB34aC7cD4A6881493f3E7bf7",
  "0x76D85B4C0Fc497EeCc38902397aC608000A06607",
  "0xCC84179FFD19A1627E79F8648d09e095252Bc418",
  "0xD5d6f8D9e784d0e26222ad3834500801a68D027D",
  "0x776198CCF446DFa168347089d7338879273172cF",
  "0xeDC5d01286f99A066559F60a585406f3878a033e",
  "0xD692Fd2D0b2Fbd2e52CFa5B5b9424bC981C30696",
  "0xca0840578f57fe71599d29375e16783424023357",
  "0xDF3A408c53E5078af6e8fb2A85088D46Ee09A61b",
  "0x743494b60097A2230018079c02fe21a7B687EAA5",
  "0x94C92F096437ab9958fC0A37F09348f30389Ae79",
  "0x5efda50f22d34F262c29268506C5Fa42cB56A1Ce",
  "0x2f50508a8a3d323b91336fa3ea6ae50e55f32185",
  "0xCEe71753C9820f063b38FDbE4cFDAf1d3D928A80",
  "0xffbac21a641dcfe4552920138d90f3638b3c9fba",
  "0x88fd245fEdeC4A936e700f9173454D1931B4C307",
  "0x09193888b3f38C82dEdfda55259A82C0E7De875E",
  "0x5cab7692D4E94096462119ab7bF57319726Eed2A",
  "0x756C4628E57F7e7f8a459EC2752968360Cf4D1AA",
  "0x722122dF12D4e14e13Ac3b6895a86e84145b6967",
  "0x94A1B5CdB22c43faab4AbEb5c74999895464Ddaf",
  "0xb541fc07bC7619fD4062A54d96268525cBC6FfEF",
  "0xD82ed8786D7c69DC7e052F7A542AB047971E73d2",
  "0xF67721A2D8F736E75a49FdD7FAd2e31D8676542a",
  "0x9AD122c22B14202B4490eDAf288FDb3C7cb3ff5E",
  "0xD691F27f38B395864Ea86CfC7253969B409c362d",
  "0xaEaaC358560e11f52454D997AAFF2c5731B6f8a6",
  "0x1356c899D8C9467C7f71C195612F8A395aBf2f0a",
  "0xA60C772958a3eD56c1F15dD055bA37AC8e523a0D",
  "0xBA214C1c1928a32Bffe790263E38B4Af9bFCD659",
  "0xb1C8094B234DcE6e03f10a5b673c1d8C69739A00",
  "0xF60dD140cFf0706bAE9Cd734Ac3ae76AD9eBC32A",
  "0x8589427373D6D84E98730D7795D8f6f8731FDA16",
  "0xB20c66C4DE72433F3cE747b58B86830c459CA911",
  "0x2573BAc39EBe2901B4389CD468F2872cF7767FAF",
  "0x527653eA119F3E6a1F5BD18fbF4714081D7B31ce",
  "0x653477c392c16b0765603074f157314Cc4f40c32",
  "0x407CcEeaA7c95d2FE2250Bf9F2c105aA7AAFB512",
  "0x833481186f16Cece3f1Eeea1a694c42034c3a0dB",
  "0xd8D7DE3349ccaA0Fde6298fe6D7b7d0d34586193",
  "0x8281Aa6795aDE17C8973e1aedcA380258Bc124F9",
  "0x57b2B8c82F065de8Ef5573f9730fC1449B403C9f",
  "0x12D66f87A04A9E220743712cE6d9bB1B5616B8Fc",
  "0x47CE0C6eD5B0Ce3d3A51fdb1C52DC66a7c3c2936",
  "0x910Cbd523D972eb0a6f4cAe4618aD62622b39DbF",
  "0xA160cdAB225685dA1d56aa342Ad8841c3b53f291",
  "0xD4B88Df4D29F5CedD6857912842cff3b20C8Cfa3",
  "0xFD8610d20aA15b7B2E3Be39B396a1bC3516c7144",
  "0x07687e702b410Fa43f4cB4Af7FA097918ffD2730",
  "0x23773E65ed146A459791799d01336DB287f25334",
  "0x22aaA7720ddd5388A3c0A3333430953C68f1849b",
  "0x03893a7c7463AE47D46bc7f091665f1893656003",
  "0x2717c5e28cf931547B621a5dddb772Ab6A35B701",
  "0xD21be7248e0197Ee08E0c20D4a96DEBdaC3D20Af",
  "0x4736dCf1b7A3d580672CcE6E7c65cd5cc9cFBa9D",
  "0xDD4c48C0B24039969fC16D1cdF626eaB821d3384",
  "0xd96f2B1c14Db8458374d9Aca76E26c3D18364307",
  "0x169AD27A470D064DEDE56a2D3ff727986b15D52B",
  "0x0836222F2B2B24A3F36f98668Ed8F0B38D1a872f",
  "0x178169B423a011fff22B9e3F3abeA13414dDD0F1",
  "0x610B717796ad172B316836AC95a2ffad065CeaB4",
  "0xbB93e510BbCD0B7beb5A853875f9eC60275CF498",
  "0x84443CFd09A48AF6eF360C6976C5392aC5023a1F",
  "0xd47438C816c9E7f2E2888E060936a499Af9582b3",
  "0x330bdFADE01eE9bF63C209Ee33102DD334618e0a",
  "0x1E34A77868E19A6647b1f2F47B51ed72dEDE95DD",
  "0xdf231d99Ff8b6c6CBF4E9B9a945CBAcEF9339178",
  "0xaf4c0B70B2Ea9FB7487C7CbB37aDa259579fe040",
  "0xa5C2254e4253490C54cef0a4347fddb8f75A4998",
  "0xaf8d1839c3c67cf571aa74B5c12398d4901147B3",
  "0x242654336ca2205714071898f67E254EB49ACdCe",
  "0x01e2919679362dFBC9ee1644Ba9C6da6D6245BB1",
  "0x2FC93484614a34f26F7970CBB94615bA109BB4bf",
  "0x26903a5a198D571422b2b4EA08b56a37cbD68c89",
];
const unwanted: string[] = [];

export const blacklist = [...banned, ...unwanted];
