import styled from "styled-components";
import { Button, Icon } from "@sablier/v2-components/molecules";
import { rgba } from "polished";
import type { ThemeType } from "@sablier/v2-themes";
import type { ComponentProps } from "react";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: span 1;
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    padding-left: calc(${(props) => props.theme.sizes.edge} * 2);
    border-left: 2px solid ${(props) => props.theme.colors.borderLanding};
  }
`;

const Box = styled.div<{ color: keyof ThemeType["colors"] }>`
  --size: 22px;
  --thickness: 1.6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
  color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  background-color: ${(props) => rgba(props.theme.colors.dark050, 0.05)};
  overflow: hidden;

  &:before {
    position: absolute;
    content: "";
    z-index: ${(props) => props.theme.sizes.zIndexUnder};
    width: 52px;
    height: 52px;
    margin-top: -70px;
    margin-right: -70px;
    background: ${(props) => props.theme.colors[props.color]};

    filter: blur(20px);
  }
  & > * {
    z-index: ${(props) => props.theme.sizes.zIndexOver};
  }
`;

const Title = styled.div`
  text-align: left;
  & > p {
    ${(props) => props.theme.styles.textTitle}
    & {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const Description = styled.div`
  max-width: 300px;
  text-align: left;
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray300};
    }
  }
`;

const Action = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    & > * {
      padding-inline: 0 !important;
      & > * {
        margin-left: 0;
      }
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    height: auto;
    padding: calc(${(props) => props.theme.sizes.edge} * 1);
    border: 2px solid ${(props) => props.theme.colors.borderLanding};
    border-radius: 4px;
  }
`;

export interface Props {
  action: ComponentProps<typeof Button>;
  className?: string;
  description: string;
  icon: ComponentProps<typeof Icon>["value"];
  iconPurpose?: ComponentProps<typeof Icon>["purpose"];
  title: string;
}

function Item({
  action,
  className,
  description,
  icon,
  iconPurpose,
  title,
}: Props) {
  return (
    <Wrapper className={className}>
      <Box color={"dark1000"}>
        <Icon purpose={iconPurpose} value={icon} />
      </Box>
      <Title>
        <p>{title}</p>
      </Title>
      <Description>
        <p>{description}</p>
      </Description>
      <Action>
        <Button {...action} />
      </Action>
    </Wrapper>
  );
}

export default Item;
