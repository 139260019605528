import chains from "./chains";

const flash: Record<number, { address: string; symbol: string }[]> = {};

const tokens: Record<number, { address: string; symbol: string }[]> = {
  [chains.arbitrum.chainId]: [
    {
      address: "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
      symbol: "USDC",
    },
    {
      address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
      symbol: "DAI",
    },
    {
      address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
      symbol: "USDT",
    },
    {
      address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
      symbol: "WETH",
    },
    {
      address: "0x912ce59144191c1204e64559fe8253a0e49e6548",
      symbol: "ARB",
    },
  ],
  [chains.avalanche.chainId]: [
    {
      address: "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
      symbol: "USDC",
    },
    {
      address: "0xd586e7f844cea2f87f50152665bcbc2c279d8d70",
      symbol: "DAI.e",
    },
    {
      address: "0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7",
      symbol: "USDT",
    },
    {
      address: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
      symbol: "WETH.e",
    },
  ],
  [chains.base.chainId]: [
    {
      address: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
      symbol: "USDC",
    },
    {
      address: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
      symbol: "DAI",
    },
    {
      address: "0x4200000000000000000000000000000000000006",
      symbol: "WETH",
    },
  ],
  [chains.bsc.chainId]: [
    {
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      symbol: "USDC",
    },
    {
      address: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
      symbol: "DAI",
    },
  ],
  [chains.sepolia.chainId]: [
    {
      address: "0x776b6fC2eD15D6Bb5Fc32e0c89DE68683118c62A",
      symbol: "DAI",
    },
  ],
  [chains.gnosis.chainId]: [
    {
      address: "0xddafbb505ad214d7b80b1f830fccc89b60fb7a83",
      symbol: "USDC",
    },
    {
      address: "0x44fA8E6f47987339850636F88629646662444217",
      symbol: "DAI",
    },
    {
      address: "0x4ecaba5870353805a9f068101a40e0f32ed605c6",
      symbol: "USDT",
    },
    {
      address: "0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1",
      symbol: "WETH",
    },
  ],
  [chains.mainnet.chainId]: [
    {
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      symbol: "USDC",
    },
    {
      address: "0x6b175474e89094c44da98b954eedeac495271d0f",
      symbol: "DAI",
    },
    {
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      symbol: "WETH",
    },
  ],
  [chains.optimism.chainId]: [
    {
      address: "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
      symbol: "USDC",
    },
    {
      address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
      symbol: "DAI",
    },
    {
      address: "0x4200000000000000000000000000000000000006",
      symbol: "WETH",
    },
  ],
  [chains.polygon.chainId]: [
    {
      address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
      symbol: "WMATIC",
    },
    {
      address: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
      symbol: "DAI",
    },
    {
      address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      symbol: "USDC",
    },
  ],
  [chains.scroll.chainId]: [
    {
      address: "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4",
      symbol: "USDC",
    },
    {
      address: "0xcA77eB3fEFe3725Dc33bccB54eDEFc3D9f764f97",
      symbol: "DAI",
    },
    {
      address: "0xf55bec9cafdbe8730f096aa55dad6d22d44099df",
      symbol: "USDT",
    },
  ],
};
const whitelist = {
  tokens,
  flash,
};

export default whitelist;
