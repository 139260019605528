import styled from "styled-components";
import type { ThemeType } from "@sablier/v2-themes";

const Wrapper = styled.svg.attrs((props) => props.theme.attributes.base)`
  position: absolute;
  width: 100%;
  height: 100%;

  rect {
    fill: none;
    stroke-width: 8px;
  }
`;

interface ElementProps {
  gradient?: keyof ThemeType["gradients"]["svgUrl"];
}

const Element = styled.rect.attrs(
  (props) => props.theme.attributes.base,
)<ElementProps>`
  stroke: ${(props) =>
    props.theme.gradients.svgUrl[props.gradient || "primary"]};
`;

function Rectangle({
  className,
  gradient = "primary",
}: {
  className?: string;
  gradient?: keyof ThemeType["gradients"]["svgUrl"];
}) {
  return (
    <Wrapper className={className} viewBox={"0 0 60 60"}>
      <Element
        gradient={gradient}
        height={"57"}
        rx={"8"}
        width={"57"}
        x={"1.5"}
        y={"1.5"}
      />
    </Wrapper>
  );
}

export default Rectangle;
