import type { Locale } from "@sablier/v2-locales";

export const StreamActionCategory = {
  APPROVAL: "Approval",
  APPROVAL_FOR_ALL: "ApprovalForAll",
  CREATE: "Create",
  CANCEL: "Cancel",
  FLASH: "FlashLoan",
  RENOUNCE: "Renounce",
  TRANSFER: "Transfer",
  WITHDRAW: "Withdraw",
} as const;

export const AirstreamActionCategory = {
  CLAIM: "Claim",
  CLAWBACK: "Clawback",
  CREATE: "Create",
} as const;

export const StreamCategory = {
  LOCKUP_LINEAR: "LockupLinear",
  LOCKUP_DYNAMIC: "LockupDynamic",
  LOCKUP_TRANCHED: "LockupTranched",
} as const;

export const StreamVersion = {
  V20: "V20",
  V21: "V21",
  V22: "V22",
} as const;

export const StreamVersionDisplayInfo = {
  [StreamVersion.V20]: { label: "V2.0" },
  [StreamVersion.V21]: { label: "V2.1" },
  [StreamVersion.V22]: { label: "V2.2" },
} as const;

export const CoreCategory = {
  LOCKUP_LINEAR_20: "LockupLinear20",
  LOCKUP_DYNAMIC_20: "LockupDynamic20",

  LOCKUP_LINEAR_21: "LockupLinear21",
  LOCKUP_DYNAMIC_21: "LockupDynamic21",

  LOCKUP_LINEAR_22: "LockupLinear22",
  LOCKUP_DYNAMIC_22: "LockupDynamic22",
  LOCKUP_TRANCHED_22: "LockupTranched22",
} as const;

export const PeripheryCategory = {
  BATCH: "batch",
  REGISTRY: "registry",
  TARGET_APPROVE: "targetApprove",
  MERKLE_LOCKUP_FACTORY: "merkleLockupFactory",
} as const;

/**
 * -------- STATUS MACHINE | THE PRIMARY FLOW  --------
 *
 * NULL
 * └──── PENDING            ┌─── SETTLED ─── DEPLETED
 *       └──── STREAMING ───│
 *                          └─── CANCELED ─── DEPLETED
 *
 * Status definitions extracted from https://github.com/sablier-labs/v2-core/blob/239a695e242d0f395d8afdb77777d39c6211a2d2/src/types/DataTypes.sol#L50
 * ┌───────────────────────────────────────────────────────────────────┐
 * | Status     | Description                                          |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | NULL       | Missing instance. Externally handled, as there's     |
 * |            | no point in creating an empty Stream entity          |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | PENDING    | Stream created but not started; assets are in a      |
 * |            | pending state                                        |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | STREAMING  | Stream where assets are currently being streamed     |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | SETTLED    | All assets have been streamed; recipient is due      |
 * |            | to withdraw them                                     |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | CANCELED   | Stream has been interrupted. Remaining assets        |
 * |            | await recipient's withdrawal                         |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | DEPLETED   | All assets have been withdrawn and/or refunded       |
 * └───────────────────────────────────────────────────────────────────┘
 *
 * The app will make no use of the NULL status (handled externally,
 * see useCurrentStream) as well as split the final state into two
 * separate endpoints for the maximum amount of extractable
 * data from the status.
 *
 * Read the "statuses" section in the docs for more context.
 */

export const StreamStatus = {
  PENDING: "Pending",
  STREAMING: "Streaming",
  CANCELED: "Canceled",
  SETTLED: "Settled",
  /** --- END STATES --- */
  DEPLETED_SETTLED: "DepletedSettled",
  DEPLETED_CANCELED: "DepletedCanceled ",
} as const;

export type StreamActionCategory =
  (typeof StreamActionCategory)[keyof typeof StreamActionCategory];
export type AirstreamActionCategory =
  (typeof AirstreamActionCategory)[keyof typeof AirstreamActionCategory];
export type CoreCategory = (typeof CoreCategory)[keyof typeof CoreCategory];
export type PeripheryCategory =
  (typeof PeripheryCategory)[keyof typeof PeripheryCategory];
export type StreamCategory =
  (typeof StreamCategory)[keyof typeof StreamCategory];
export type StreamStatus = (typeof StreamStatus)[keyof typeof StreamStatus];
export type StreamVersion = (typeof StreamVersion)[keyof typeof StreamVersion];
/**
 * ------------------------------------------------------------
 * STREAM SHAPE - Naming conventions
 *
 * - LockupLinear streams will have simple names ["linear", "cliff"]
 * - LockupDynamic streams will be prefixed with the "dynamic" particle
 * - LockupDynamic streams without bespoke names (e.g. "timelock") will follow the order of their segments in the name (e.g. "cliff" + "exponential")
 * ------------------------------------------------------------
 */
export type StreamShape =
  | "cliff"
  | "linear"
  | "dynamicMonthly"
  | "dynamicStepper"
  | "dynamicTimelock"
  | "dynamicUnlockLinear"
  | "dynamicUnlockCliff"
  | "dynamicCliffExponential"
  | "dynamicExponential"
  | "tranchedBackweighted"
  | "tranchedStepper"
  | "tranchedMonthly"
  | "tranchedTimelock"
  | "unknown";

export const StreamShapes: Record<
  StreamShape,
  {
    description: Locale;
    id: StreamShape | string;
    isUnknown?: true;
    title: Locale;
  }
> = {
  cliff: {
    id: "cliff",
    description: "shapes.cliff.description",
    title: "shapes.cliff.title",
  },
  linear: {
    id: "linear",
    description: "shapes.linear.description",
    title: "shapes.linear.title",
  },
  dynamicExponential: {
    id: "dynamicExponential",
    description: "shapes.dynamicExponential.description",
    title: "shapes.dynamicExponential.title",
  },
  dynamicCliffExponential: {
    id: "dynamicCliffExponential",
    description: "shapes.dynamicCliffExponential.description",
    title: "shapes.dynamicCliffExponential.title",
  },
  dynamicMonthly: {
    id: "dynamicMonthly",
    description: "shapes.dynamicMonthly.description",
    title: "shapes.dynamicMonthly.title",
  },
  dynamicStepper: {
    id: "dynamicStepper",
    description: "shapes.dynamicStepper.description",
    title: "shapes.dynamicStepper.title",
  },
  dynamicUnlockCliff: {
    id: "dynamicUnlockCliff",
    description: "shapes.dynamicUnlockCliff.description",
    title: "shapes.dynamicUnlockCliff.title",
  },
  dynamicUnlockLinear: {
    id: "dynamicUnlockLinear",
    description: "shapes.dynamicUnlockLinear.description",
    title: "shapes.dynamicUnlockLinear.title",
  },
  dynamicTimelock: {
    id: "dynamicTimelock",
    description: "shapes.dynamicTimelock.description",
    title: "shapes.dynamicTimelock.title",
  },
  tranchedBackweighted: {
    id: "tranchedBackweighted",
    description: "shapes.tranchedBackweighted.description",
    title: "shapes.tranchedBackweighted.title",
  },
  tranchedStepper: {
    id: "tranchedStepper",
    description: "shapes.tranchedStepper.description",
    title: "shapes.tranchedStepper.title",
  },
  tranchedMonthly: {
    id: "tranchedMonthly",
    description: "shapes.tranchedMonthly.description",
    title: "shapes.tranchedMonthly.title",
  },
  tranchedTimelock: {
    id: "tranchedTimelock",
    description: "shapes.tranchedTimelock.description",
    title: "shapes.tranchedTimelock.title",
  },
  unknown: {
    id: "unknown",
    isUnknown: true,
    description: "structs.notDefined",
    title: "words.stream",
  },
};
