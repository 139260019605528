import { BigNumber, _ } from "@sablier/v2-mixins";
import type { IValue } from "@sablier/v2-types";
import chains from "./chains";

export const BASE_INPUT_PRECISION = 6;
export const DEFAULT_BROKER_ADDRESS =
  "0x0000000000000000000000000000000000000000";

export const DEFAULT_BROKER_FEE_PERCENTAGE = "0";
export const DEFAULT_CHAIN_ID = chains.mainnet.chainId;
export const DEFAULT_GAS_LIMIT = 300_000;
export const DEFAULT_MAX_GAS_LIMIT = 15_000_000;
export const DEFAULT_GAS_BUMP = 50_000;
export const DEFAULT_DECIMALS = 18;

export const MAX_AMOUNT = new BigNumber(10).pow(20).toString();
export const MAX_AMOUNT_PADDED = (decimals = DEFAULT_DECIMALS, level = 15) =>
  new BigNumber(10).pow(decimals + level).toString();
export const DEFAULT_RESET_SLEEP = 9 * 1000;
export const DEFAULT_SAFE_POLLING_SLEEP = 5 * 1000;
export const DEFAULT_WITHDRAW_SLIPPAGE = 5;

export const DEFAULT_CLIFF_EXCESS = 59 * 1000; /* ~ 1 minute */
export const DEFAULT_END_DATE_EXCESS = 29 * 60 * 1000; /* ~ 30 minutes */

export const QUERY_CACHE_BLOCK_TIME = 13 * 1000;
export const QUERY_CACHE_ENS_TIME = 60 * 60 * 1000; /* ~ 60 minutes */
export const QUERY_CACHE_TIME = 24 * 60 * 1000;
export const QUERY_PERSIST_TIME = 30 * 24 * 60 * 60 * 1000;

export const EXPONENT_DECIMALS = 18;
export const PERCENTAGE_DECIMALS = 18;

export const DEFAULT_UI_POLLING = 5 * 1000;
export const FAST_UI_POLLING = 0.1 * 1000;

export const HISTORY_PAGE_SIZE = 21;
export const STREAMS_PAGE_SIZE = 31;
export const AIRSTREAMS_PAGE_SIZE = 31;
export const AIRSTREAMS_FEW_RECIPIENTS = 10;
export const AIRSTREAMS_RECIPIENTS_PAGE_SIZE = 100;
export const AIRSTREAMS_RECIPIENTS_MAX_SIZE =
  AIRSTREAMS_RECIPIENTS_PAGE_SIZE * 6;
export const AIRSTREAMS_RECIPIENTS_VALIDATION_THRESHOLD = 200;

export const AIRSTREAM_GRACE_DAYS = 7;

export const MAX_CANCEL_STREAM_SET = 60;
export const MAX_WITHDRAW_STREAM_SET = 60;
export const MAX_GROUP_STREAM_SIZE = 60;

export const MAX_SUBGRAPH_ID = new BigNumber(10).pow(9).toString();
export const MAX_SAFE_TX_ATTEMPTS = 600;

export const TOKEN_FAUCET_AMOUNT = 10_000; /** The app assumes the testnet token is using 18 decimals. */

export const MODAL_PORTAL_ID = "sablier-modal__container";
export const STEPPER_MAX_STEPS_SINGLE = 150;
export const STEPPER_MAX_STEPS_GROUP = 60;
export const STEPPER_MIN_STEPS = 1;

export const MONTHLY_MAX_UNLOCKS_GROUP = 60;
export const MONTHLY_MAX_UNLOCKS_SINGLE = 150;
export const MONTHLY_MIN_UNLOCKS = 1;

export const BACKWEIGHTED_YEARS_MIN = 2;
export const BACKWEIGHTED_YEARS_MAX = 10;

export const MAX_AIRSTREAM_CAMPAIGN_NAME = 32;

export const SUBGRAPH_FALLBACK_PARTY = "0x0611B";
export const GROUP_STREAM_DATE_FORMATS_CSV = [
  "YYYY-MM-DD HH:mm",
  "YYYY-M-D HH:mm",
  "YYYY-M-DD HH:mm",
  "YYYY-MM-D HH:mm",
];
export const FILE_TYPE_ACCEPTED_CSV =
  ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
export const RATE_LIMITED_EXCEPTION = "Rate limited";
export const RATE_LIMITED_CAP = 200;

export const REQUEST_ID = {
  airstream: ["airstream"],
  airstreamActions: ["airstream", "actions"],
  airstreamDetails: ["airstream", "details"],
  airstreamItem: ["airstream", "item"],
  airstreamOnchain: ["airstream", "onchain"],
  airstreamItemMetadata: ["airstream", "metadata"],
  airstreamItemPreview: ["airstream", "item", "preview"],
  airstreamItemPreviewMetadata: ["airstream", "item", "preview", "metadata"],
  airstreamList: ["airstream", "list"],
  airstreamListEligible: ["airstream", "list", "eligible"],
  airstreamListHidden: ["airstream", "list", "hidden"],
  airstreamListClaimable: ["airstream", "list", "claimable"],
  airstreamListOwned: ["airstream", "list", "owned"],
  airstreamListSearch: ["airstream", "list", "search"],
  airstreamListIdentifiers: ["airstream", "list", "identifiers"],
  airstreamRecipientsFromFile: ["_airstream", "recipients", "file"],
  expected: ["expected"],
  geolocation: "geolocation",
  polling: ["polling"],
  multisigOwners: ["multisig", "owners"],
  stream: ["stream"],
  streamActions: ["stream", "actions"],
  streamItem: ["stream", "item"],
  streamItemNFT: ["stream", "item", "nft"],
  streamItemPreview: ["stream", "item", "preview"],
  streamItemWithdrawable: ["stream", "item", "withdraw"],
  streamItemsWithdrawable: ["stream", "items", "withdraw"],
  streamList: ["stream", "list"],
  streamListOwned: ["stream", "list", "owned"],
  streamListSearch: ["stream", "list", "search"],
  streamListIdentifiers: ["stream", "list", "identifiers"],
  streamOnchain: ["stream", "onchain"],
  tokenList: ["token", "list"],
  tokenBalance: ["token", "balance"],
  trm: ["trm"],
  trmScreening: ["trmScreening"],
  withdrawable: ["withdrawable"],
  proxy: ["proxy"],
  reCaptcha: ["reCaptcha"],
  sablierServiceMerkleCreate: ["sablier-v2-services", "merkle", "create"],
  sablierServiceMerkleEligible: ["sablier-v2-services", "merkle", "eligible"],
  sablierServiceMerkleValidity: ["sablier-v2-services", "merkle", "validity"],
  sablierServiceMerkleDetails: ["sablier-v2-services", "merkle", "details"],
  sablierServiceMerkleUpdate: ["sablier-v2-services", "merkle", "update"],

  /**---------------- */

  wagmiBalance: ["balance", { entity: "balance" }],

  /**---------------- */

  persisted: ["trm", "trmScreening"],
};

export enum DigitPurpose {
  ABBREVIATION = "abbreviation",
  CHARACTERISTIC = "characteristic",
  DIVIDER = "divider",
  MANTISSA = "mantissa",
  PREFIX = "prefix",
  SUFFIX = "suffix",
}

export const DigitLimits = {
  ABBREVIATE_PRECISION: 10,
  MANTISSA_PRECISION_FLOOR: 3,
  MANTISSA_PRECISION: 8,
  MANTISSA_SLICE_PRECISION: 6,
  STATIC_PRECISION: [5, 7], // Don't change the number (outside of prefix/suffix) until it reaches 5 characteristic and 7 mantissa digits
};

export const ZERO = (
  decimals: BigNumber | number | string = DEFAULT_DECIMALS,
): IValue => _.toValue({ decimals, raw: "0" });
