import { useMemo } from "react";
import styled from "styled-components";
import {
  ArrowUpRightIcon,
  BookOpenIcon,
  BuildingOfficeIcon,
  LinkIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from "@sablier/v2-assets/logo/long-white.svg";
import { Internal } from "@sablier/v2-components/atoms";
import { Button } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { routes } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { useRouter } from "next/router";
import { rgba } from "polished";

const WrapperPartial = styled.nav`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    height: ${(props) => props.theme.sizes.toolbar};
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.row}
  & {
    justify-content: space-between;

    height: 100%;
  }
`;

const Branding = styled(Internal)`
  width: 150px;
  height: 100%;
  margin-right: auto;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Menu = styled.menu`
  ${(props) => props.theme.styles.row}
  & {
    height: 100%;
    margin: 0;
    padding: 0;
  }
`;

const Item = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    height: 100%;
    margin-left: -1px;
    padding: ${(props) => props.theme.sizes.edge};
    border-radius: 0 0 0px 0px;
    background-color: ${(props) => props.theme.colors.transparent};
    cursor: pointer;

    &:after {
      position: absolute;
      top: -2px;
      left: 0;
      content: "";
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 1px solid ${(props) => rgba(props.theme.colors.white, 0.1)};
      border-top: none;
      border-radius: 0 0 300px 1px;
      background-color: ${(props) => rgba(props.theme.colors.white, 0.05)};
      transform: translateY(-102%);
      transition: border-radius 150ms, transform 150ms;
    }
    & > * {
      position: relative;
      z-index: 2;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${Item} {
    &[data-active="true"] {
      pointer-events: none;
    }
    &:not([data-active="true"]) {
      &:hover {
        &:after {
          border-radius: 0 0 4px 4px;
          transform: translateY(0);
          transition: border-radius 300ms ease-out, transform 300ms ease-out;
        }
      }
    }
  }
  ${(props) => props.theme.medias.maxLG} {
    ${Item} {
      padding-inline: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    }
  }

  ${(props) => props.theme.medias.minMD} {
    ${Item} {
      *[data-component="side"] {
        display: none;
      }
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    ${Branding} {
      flex-shrink: 0;
      width: 140px;
    }
    ${Item} {
      padding-inline: 0;
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Container} {
      padding: 0 calc(${(props) => props.theme.sizes.edge} * 1 / 4);
    }
    ${Branding} {
      flex-shrink: 0;
      width: 130px;
    }
    ${Menu} {
      gap: 8px;
      ${Item} {
        padding: 0;
        &:after {
          display: none;
        }
        &:hover {
          background: none;
          *[data-component="button"] {
            border: 2px solid ${(props) => props.theme.colors.dark400};
            background: ${(props) => props.theme.colors.dark200};
          }
        }
        &[data-active="true"] {
          *[data-component="button"] {
            position: relative;
            &:before {
              position: absolute;
              top: -4px;
              left: -4px;
              content: "";
              z-index: 10;
              flex-shrink: 0;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: ${(props) =>
                props.theme.colors.secondaryMiddle};
            }
          }
        }
        *[data-component="button"] {
          border: 2px solid ${(props) => props.theme.colors.dark250};
        }
      }
    }
  }

  ${(props) => props.theme.medias.max(700)} {
    ${Item} {
      &[data-mobile="false"] {
        display: none;
      }
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Menu} {
      gap: 4px;
      & > *:last-child {
        display: none;
      }
    }
  }

  ${(props) => props.theme.medias.maxXXS} {
    ${Branding} {
      flex-shrink: 0;
      width: 100px;
    }
  }
`;

function Toolbar() {
  const { pathname } = useRouter();
  const { t } = useT();

  const isNotHome = useMemo(
    () => [routes.landing.pages.organizations.route].includes(pathname),
    [pathname],
  );

  return (
    <Wrapper>
      <Container>
        <Branding to={routes.landing.pages.home.builder()}>
          <Logo />
        </Branding>

        <Menu>
          <Item data-active={!isNotHome}>
            <Button
              accent={!isNotHome ? "secondaryMiddle" : "gray100"}
              appearance={"transparent"}
              left={UserIcon}
              purpose={"internal"}
              title={t("pages.home")}
              titleMedium={t("pages.you")}
              titleShort={""}
              to={routes.landing.pages.home.builder()}
            />
          </Item>
          <Item data-active={isNotHome}>
            <Button
              accent={isNotHome ? "secondaryMiddle" : "gray100"}
              appearance={"transparent"}
              left={BuildingOfficeIcon}
              purpose={"internal"}
              title={t("pages.organizations")}
              titleMedium={t("pages.orgs")}
              titleShort={""}
              to={routes.landing.pages.organizations.builder()}
            />
          </Item>
          <Item data-mobile={false}>
            <Button
              accent={"gray100"}
              appearance={"transparent"}
              left={BookOpenIcon}
              purpose={"external"}
              title={t("words.docs")}
              to={links.docs.v2}
            />
          </Item>
          <Item>
            <Button
              accent={"gray100"}
              appearance={"transparent"}
              left={LinkIcon}
              purpose={"external"}
              title={t("words.contact")}
              titleShort={""}
              to={links.linktree}
            />
          </Item>
          <Item>
            <Button
              accent={"gray100"}
              appearance={"transparent"}
              left={ArrowUpRightIcon}
              purpose={"external"}
              title={t("words.app")}
              titleShort={""}
              to={links.v2.client}
            />
          </Item>
        </Menu>
      </Container>
    </Wrapper>
  );
}

export default Toolbar;
