interface Props {
  symbol: string;
}

const Emoji = ({ symbol }: Props) => {
  return (
    <span data-component={"emoji"} role={"img"}>
      {symbol}
    </span>
  );
};

export default Emoji;
