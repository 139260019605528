import { useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import Link from "next/link";
import type { PropsWithChildren } from "react";

const Wrapper = styled.div``;

interface Props {
  href?: string;
  isReplacing?: boolean;
  target?: string;
  to?: string;
  [key: string]: unknown;
}

export default function Internal({
  children,
  href,
  isReplacing = false,
  to,
  ...props
}: PropsWithChildren<Props>) {
  const link = useMemo(() => {
    if (_.isNilOrEmptyString(href) && !_.isNilOrEmptyString(to)) {
      return to;
    }
    return href;
  }, [href, to]);

  if (_.isNilOrEmptyString(link)) {
    return <Wrapper {...props}>{children}</Wrapper>;
  }

  return (
    <Link
      href={link as string}
      passHref
      replace={isReplacing}
      scroll
      shallow
      legacyBehavior
    >
      <a {...props}>{children}</a>
    </Link>
  );
}
