import { useCallback, useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import Trans from "next-translate/Trans";
import getT from "next-translate/getT";
import useTranslation from "next-translate/useTranslation";
import type Common from "./en/common.json";
import type { RecursiveSlug } from "@sablier/v2-types";
import type { Translate } from "next-translate";

type Locale = RecursiveSlug<typeof Common>;
type Transform = (
  key: Locale,
  query?: Parameters<Translate>["1"],
  options?: Parameters<Translate>["2"],
) => string;

type ITransParams = Parameters<typeof Trans>["0"] & { i18nKey: Locale };
type ITrans = ({
  i18nKey,
  values,
  components,
  fallback,
  defaultTrans,
  ns,
}: ITransParams) => ReturnType<typeof Trans>;
/**
 * Use Fallback Translation
 * ____*________*__________
 */
function useFT(value: string | number | undefined | null, fallback: Locale) {
  const { t } = useTranslation();
  return useMemo(() => {
    return _.isNilOrEmptyString(value) ? t(fallback) : _.toString(value);
  }, [fallback, value, t]);
}

/**
 * Use Translation
 * ____*__________
 */

function useT() {
  const { t: baseT, lang } = useTranslation();

  const t: Transform = useCallback(
    (
      key: Locale,
      query?: Parameters<Translate>["1"],
      options?: Parameters<Translate>["2"],
    ) => baseT(key, query, options) as string,
    [baseT],
  );

  return {
    t,
    lang,
  };
}

const Component: ITrans = Trans;

async function getSafeT(locale: string, namespace: string | string[]) {
  /** https://github.com/aralroca/next-translate/issues/484 */

  const config = await import(`./i18n.base`);
  global.i18nConfig = config;

  return getT(locale, namespace);
}

export { getSafeT as getT, useT, useFT, Component as Trans };
export type { Transform as Translate, Locale };
