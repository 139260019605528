import { memo } from "react";
import styled from "styled-components";
import type { Props as IconProps } from "../../Icon";
import Icon from "../../Icon";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    flex-shrink: 0;
    justify-content: center;
    width: auto;
    min-width: 18px;
  }
`;

export interface Props {
  color?: IconProps["color"]; // Only used for components that cannot dynamically get the currentColor from css
  isMini?: boolean;
  purpose: IconProps["purpose"];
  value?: IconProps["value"];
}

function Side({ isMini, ...props }: Props) {
  return (
    <Wrapper data-component={"side"}>
      <Icon {...props} size={isMini ? 16 : undefined} />
    </Wrapper>
  );
}

export default memo(Side);
