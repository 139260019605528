import styled from "styled-components";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import AssetCantina from "@sablier/v2-assets/brands/cantina.png";
import AssetCode from "@sablier/v2-assets/screenshots/code.png";
import { Button } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import Image from "next/image";
import SectionPartial from "../Section";

const WrapperPartial = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
`;

const Section = styled(SectionPartial)``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: calc(${(props) => props.theme.sizes.edge} * 1);
  width: 100%;
  padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
`;

const Cell = styled.div<{ size?: number }>`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: span ${(props) => props.size || 1};
    position: relative;
    flex-shrink: 0;
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
    justify-content: center;
    align-items: center;
    padding: calc(${(props) => props.theme.sizes.edge} * 2);
    border: 2px solid ${(props) => props.theme.colors.borderLanding};
    border-radius: 4px;
    overflow: hidden;
    & > * {
      z-index: ${(props) => props.theme.sizes.zIndexOver};
    }
  }
`;

const Logo = styled.div`
  position: relative;
  width: 200px;
  height: 50px;

  img {
    object-fit: contain;
  }
`;

const Title = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    justify-content: center;
    align-items: center;
    min-height: 50px;
    text-align: center;
    & > p {
      ${(props) => props.theme.styles.textTitle}
      & {
        color: ${(props) => props.theme.colors.white};
        font-size: 16pt;
        line-height: 140%;
      }
    }
  }
`;

const Description = styled.div`
  max-width: 400px;
  text-align: center;
  & > p {
    ${(props) => props.theme.styles.textParagraphMini}
    & {
      color: ${(props) => props.theme.colors.gray300};
    }
  }
`;

const Action = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    & > * {
      padding-inline: 0;
    }
  }
`;

const Code = styled.div`
  position: absolute;
  top: -6px;
  left: 16px;
  z-index: ${(props) => props.theme.sizes.zIndexUnder};
  width: 300px;
  height: 300px;
  background-color: ${(props) => props.theme.colors.dark000};
  overflow: hidden;

  img {
    mix-blend-mode: lighten;
    opacity: 0.3;
    filter: grayscale(100%);
    transform: rotate(0deg);
    object-fit: contain;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    ${Section} {
      div[data-component="top"] {
        align-items: center;
        width: 100%;
        * {
          align-items: center;
          text-align: center;
        }
      }
    }
    ${Grid} {
      grid-template-columns: repeat(3, 1fr);
      gap: calc(${(props) => props.theme.sizes.edge} * 1);
      & > * {
        grid-column: span 1;
        &:last-child {
          grid-column: span 3;
        }
      }
    }
    ${Cell} {
      padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    }
    ${Logo} {
      width: 100%;
      max-width: 200px;
    }
  }

  ${(props) => props.theme.medias.maxMD} {
    ${Grid} {
      grid-template-columns: 1fr 1fr;
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      padding-top: 0;
      & > * {
        grid-column: span 1;
        &:nth-child(3) {
          grid-column: span 2;
        }
        &:last-child {
          grid-column: span 2;
        }
      }
    }
    ${Cell} {
      padding: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Cell} {
      padding: calc(${(props) => props.theme.sizes.edge} * 1);
      ${Logo} {
        width: 100%;
        max-width: 140px;
      }
    }
    ${Grid} {
      grid-template-columns: repeat(1, 1fr);
      gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      & > * {
        grid-column: span 1 !important;
      }
      ${Code} {
        opacity: 0.5;
      }
    }
  }
`;

function Audit() {
  const { t } = useT();

  return (
    <Wrapper id={"audit"}>
      <Section
        color={"primaryMiddle"}
        description={t("landing.audit.description")}
        label={t("landing.audit.label")}
        title={t("landing.audit.title")}
      >
        <Grid>
          <Cell>
            <Logo>
              <Image alt={"Cantina"} fill src={AssetCantina} sizes={"100vw"} />
            </Logo>
            <Action>
              <Button
                accent={"gray400"}
                appearance={"transparent"}
                isMini
                purpose={"external"}
                right={ChevronRightIcon}
                title={t("structs.securityAudit", { count: 1 })}
                to={links.security.audits}
              />
            </Action>
          </Cell>
          <Cell>
            <Title>
              <p>{t("landing.audit.other")}</p>
            </Title>
            <Action>
              <Button
                accent={"gray400"}
                appearance={"transparent"}
                isMini
                purpose={"external"}
                right={ChevronRightIcon}
                title={t("structs.securityAudit", { count: 2 })}
                to={links.security.audits}
              />
            </Action>
          </Cell>
          <Cell>
            <Title>
              <p>{t("landing.audit.bounty")}</p>
            </Title>
            <Action>
              <Button
                accent={"gray400"}
                appearance={"transparent"}
                isMini
                purpose={"external"}
                right={ChevronRightIcon}
                title={t("structs.viewBounty")}
                to={links.security.bounty}
              />
            </Action>
          </Cell>

          <Cell size={3}>
            <Title>
              <p>{t("landing.audit.repo.title")}</p>
            </Title>
            <Description>
              <p>{t("landing.audit.repo.description")}</p>
            </Description>
            <Action>
              <Button
                accent={"gray400"}
                appearance={"transparent"}
                isMini
                purpose={"external"}
                right={ChevronRightIcon}
                title={t("landing.audit.repo.action")}
                to={links.security.audits}
              />
            </Action>
            <Code>
              <Image alt={"Code"} fill src={AssetCode} sizes={"50vw"} />
            </Code>
          </Cell>
        </Grid>
      </Section>
    </Wrapper>
  );
}

export default Audit;
