import styled from "styled-components";
import AssetGrid from "@sablier/v2-assets/elements/grid-pico-pattern.svg";
import { Decor } from "@sablier/v2-components/atoms";

const WrapperPartial = styled.div`
  position: absolute;
  top: 50px;
  left: 50vw;
  z-index: ${(props) => props.theme.sizes.zIndexUnder};
  width: 100%;
  height: calc(100% + 200px);
`;

const Mesh = styled.div`
  position: absolute;
  top: -160px;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-image: url("${AssetGrid}");
  background-repeat: repeat;
  background-position: top center;
  opacity: 0.035;
`;
const Fade = styled.div`
  position: absolute;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 200px;
  background: linear-gradient(
    0deg,
    transparent,
    ${(props) => props.theme.colors.dark000}
  );
`;

const FadeTop = styled(Fade)`
  top: -200px;
`;
const FadeBottom = styled(Fade)`
  bottom: 0px;
`;

const Background = styled(Decor.Background)`
  background-color: ${(props) => props.theme.colors.dark000} !important;
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    display: none;
  }
`;

function Underlay() {
  return (
    <Wrapper>
      <Background left={"none"} right={"none"}>
        <FadeTop />
        <FadeBottom />
        <Mesh />
      </Background>
    </Wrapper>
  );
}

export default Underlay;
