import styled, { css, keyframes } from "styled-components";
import type { ThemeType } from "@sablier/v2-themes";

const circleKeyframes = keyframes`
  0%{
    transform: rotate(0);
  }
  100%{
    transform: rotate(360deg);
  }
`;

const WrapperPartial = styled.svg.attrs((props) => props.theme.attributes.base)`
  position: absolute;

  circle {
    fill: none;
    stroke-width: 8px;
  }
`;

interface ElementProps {
  gradient?: keyof ThemeType["gradients"]["svgUrl"];
}

const Element = styled.circle.attrs(
  (props) => props.theme.attributes.base,
)<ElementProps>`
  stroke: ${(props) =>
    props.theme.gradients.svgUrl[props.gradient || "primary"]};
`;

const Wrapper = styled(WrapperPartial)<{ isAnimated?: boolean }>`
  ${(props) =>
    props.isAnimated &&
    css`
      animation-name: ${circleKeyframes};
      animation-duration: 20000ms;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      animation-iteration-count: infinite;
      stroke-linecap: round;
      stroke-dashoffset: 1000;
      stroke-dasharray: 300;
    `}
`;

function Circle({
  className,
  gradient = "primary",
  isAnimated = false,
}: {
  className?: string;
  gradient?: keyof ThemeType["gradients"]["svgUrl"];
  isAnimated?: boolean;
}) {
  return (
    <Wrapper
      isAnimated={isAnimated}
      className={className}
      viewBox={"0 0 60 60"}
    >
      <Element cx={"30"} cy={"30"} gradient={gradient} r={"27"} />
    </Wrapper>
  );
}

export default Circle;
