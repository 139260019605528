import { useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "@sablier/v2-assets/logo/long-gradient.svg";
import { External } from "@sablier/v2-components/atoms";
import { Label } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { pages } from "@sablier/v2-constants/routes/landing";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import Underlay from "./Underlay";

const WrapperPartial = styled.footer`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    width: 100%;
    border-top: 2px solid ${(props) => props.theme.colors.borderLanding};
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.row}
  & {
    z-index: 30;
    gap: calc(${(props) => props.theme.sizes.edge} * 4);
    justify-content: center;
    height: 100%;
    padding-top: calc(${(props) => props.theme.sizes.edge} * 4);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 4);
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
    justify-content: flex-end;
    height: 100%;
    margin-top: auto;
    & > *:first-child {
      margin-bottom: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    }
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 3);
    align-items: flex-start;
    height: 100%;
  }
`;

const Column = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
    flex-shrink: 0;
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
    justify-content: flex-start;
    height: 100%;
  }
`;

const Title = styled(Label)`
  ${(props) => props.theme.styles.capital}
  & {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
    color: ${(props) => props.theme.colors.gray100};
    white-space: nowrap;
  }
`;

const Item = styled(External)`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    ${(props) => props.theme.styles.capital}
    & {
      flex-shrink: 0;
      color: ${(props) => props.theme.colors.gray400};
      white-space: nowrap;

      &:hover,
      &:active {
        color: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

const Branding = styled.div`
  width: 150px;
  height: 100%;
  margin-right: auto;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const About = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray400};
      line-height: 140%;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    ${Content} {
      ${(props) => props.theme.styles.column}
      & {
        gap: calc(${(props) => props.theme.sizes.edge} * 2);
        width: 100%;
        height: auto;
        padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
        padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
        background-color: ${(props) => props.theme.colors.dark050};
      }
    }
    ${Left} {
      width: 100%;
      margin-top: 0;
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);
      border-bottom: 2px solid ${(props) => props.theme.colors.dark250};
      & > *:first-child {
        margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      }

      ${Branding} {
        width: 120px;
        object-position: left center;
      }
    }
    ${Right} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: calc(${(props) => props.theme.sizes.edge} * 2);
      height: auto;
      padding: 0;
    }
    ${Column} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1);
      height: auto;
      ${Title} {
        margin-bottom: 0;
        label {
          font-weight: 700;
        }
      }
      ${Item} {
        & > p {
          color: ${(props) => props.theme.colors.gray400};
        }
      }
    }
  }
  ${(props) => props.theme.medias.betweenSMAndMD} {
    ${Right} {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Right} {
      grid-template-columns: 1fr;
    }
  }
`;

function Footer() {
  const { t } = useT();

  const items = useMemo(
    () => [
      {
        title: t("words.explore"),
        list: [
          {
            title: t("structs.openTheApp"),
            to: links.v2.client,
          },
          {
            title: t("structs.reportAnIssue"),
            to: links.discord,
          },
          {
            title: t("structs.requestAFeature"),
            to: links.canny,
          },
          {
            title: t("structs.goToV1"),
            to: links.v1.app,
          },
        ],
      },
      {
        list: [
          {
            title: t("structs.privacyPolicy"),
            to: links.legal.privacy,
          },
          {
            title: _.startCase(t("structs.termsOfService")),
            to: links.legal.terms,
          },
          {
            title: _.startCase(t("structs.riskNotice")),
            to: links.legal.risk,
          },
          {
            title: _.startCase(t("words.pricing")),
            to: `${pages.organizations.builder()}#pricing`,
            target: "_self",
          },
        ],
      },
      {
        title: t("structs.reachOut"),
        list: [
          {
            title: t("structs.scheduleCall"),
            to: links.discord,
          },
          {
            title: t("words.email"),
            to: `mailto: ${links.email.contact}`,
          },
          {
            title: t("words.twitter"),
            to: links.twitter.product,
          },
          {
            title: t("words.discord"),
            to: links.discord,
          },
        ],
      },
      {
        list: [
          {
            title: t("words.blog"),
            to: links.blog,
          },
          {
            title: t("words.docs"),
            to: links.docs.v2,
          },
          {
            title: t("words.github"),
            to: links.github.organization,
          },
        ],
      },
    ],
    [t],
  );

  return (
    <Wrapper id={"footer"}>
      <Content>
        <Left>
          <Branding>
            <Logo />
          </Branding>
          <About>
            <p>{t("about.motto")}</p>
          </About>
          <About>
            <p>{t("about.timestamp")}</p>
          </About>
        </Left>
        <Right>
          {items.map((group, index) => (
            <Column key={index}>
              {!_.isNilOrEmptyString(group.title) ? (
                <Title value={group.title} />
              ) : (
                <Title value={"‎"} />
              )}
              {group.list.map(({ title, ...props }) => (
                <Item key={title} {...props}>
                  <p>{title}</p>
                </Item>
              ))}
            </Column>
          ))}
        </Right>
      </Content>
      <Underlay />
    </Wrapper>
  );
}

export default Footer;
