export const DEFAULT_TOKEN_LIST = {
  fallback: "https://tokens.sablier.io",
  url: "https://community-token-list-sablier.vercel.app/sablier-community.tokenlist.json",
  homepage: "https://github.com/sablier-labs/community-token-list",
};

export const OTHER_TOKEN_LISTS = [
  {
    icon: "https://files.sablier.com/icon-180x180.png",
    title: "Community",
    url: "https://tokens.sablier.io",
  },
  {
    icon: "https://www.coingecko.com/favicon.ico",
    title: "CoinGecko",
    url: "https://tokens.coingecko.com/uniswap/all.json",
  },
  {
    icon: "https://logo.assets.tkn.eth.limo/",
    title: "TKN.eth",
    url: "https://list.tkn.eth.limo/",
  },
  {
    icon: "https://cloudflare-ipfs.com/ipfs/QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir/",
    title: "Uniswap",
    url: "https://tokens.uniswap.org/",
  },
  {
    icon: "https://static.optimism.io/optimism.svg",
    title: "Superchain OP",
    url: "https://static.optimism.io/optimism.tokenlist.json",
  },
];

// AAVE List: https://github.com/bgd-labs/aave-address-book/blob/main/tokenlist.json#L6042
// COMPOUND V3 List: https://docs.compound.finance/#developer-resources
export const REBASING_TOKENS: { chainId: number; address: string }[] = [
  {
    chainId: 1,
    address: "0xc3d688B66703497DAA19211EEdff47f25384cdc3",
  },
  {
    chainId: 1,
    address: "0xA17581A9E3356d9A858b789D68B4d866e593aE94",
  },
  {
    chainId: 137,
    address: "0xF25212E676D1F7F89Cd72fFEe66158f541246445",
  },
  {
    chainId: 42161,
    address: "0xA5EDBDD9646f8dFF606d7448e414884C7d905dCA",
  },
  {
    chainId: 42161,
    address: "0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf",
  },
  {
    chainId: 8453,
    address: "0xb125E6687d4313864e53df431d5425969c15Eb2F",
  },
  {
    chainId: 8453,
    address: "0x9c4ec768c28520B50860ea7a15bd7213a9fF58bf",
  },
  {
    chainId: 8453,
    address: "0x46e6b214b524310239732D51387075E0e70970bf",
  },
  {
    chainId: 534352,
    address: "0xB2f97c1Bd3bf02f5e74d13f02E3e26F93D77CE44",
  },
  {
    chainId: 10,
    address: "0x2e44e174f7D53F0212823acC11C01A11d58c5bCB",
  },
  {
    chainId: 11155111,
    address: "0xAec1F48e02Cfb822Be958B68C7957156EB3F0b6e",
  },
  {
    chainId: 11155111,
    address: "0x2943ac1216979aD8dB76D9147F64E61adc126e96",
  },

  // AAVE
  {
    chainId: 1,
    address: "0xf9Fb4AD91812b704Ba883B11d2B576E890a6730A",
  },
  {
    chainId: 1,
    address: "0x79bE75FFC64DD58e66787E4Eae470c8a1FD08ba4",
  },
  {
    chainId: 1,
    address: "0xd24946147829DEaA935bE2aD85A3291dbf109c80",
  },
  {
    chainId: 1,
    address: "0x17a79792Fe6fE5C95dFE95Fe3fCEE3CAf4fE4Cb7",
  },
  {
    chainId: 1,
    address: "0x13B2f6928D7204328b0E8E4BCd0379aA06EA21FA",
  },
  {
    chainId: 1,
    address: "0x9303EabC860a743aABcc3A1629014CaBcc3F8D36",
  },
  {
    chainId: 1,
    address: "0xc58F53A8adff2fB4eb16ED56635772075E2EE123",
  },
  {
    chainId: 1,
    address: "0xe59d2FF6995a926A574390824a657eEd36801E55",
  },
  {
    chainId: 1,
    address: "0xA1B0edF4460CC4d8bFAA18Ed871bFF15E5b57Eb4",
  },
  {
    chainId: 1,
    address: "0xE340B25fE32B1011616bb8EC495A4d503e322177",
  },
  {
    chainId: 1,
    address: "0x0ea20e7fFB006d4Cfe84df2F72d8c7bD89247DB0",
  },
  {
    chainId: 1,
    address: "0xb8db81B84d30E2387de0FF330420A4AAA6688134",
  },
  {
    chainId: 1,
    address: "0xb8db81B84d30E2387de0FF330420A4AAA6688134",
  },
  {
    chainId: 1,
    address: "0xA9e201A4e269d6cd5E9F0FcbcB78520cf815878B",
  },
  {
    chainId: 1,
    address: "0x38E491A71291CD43E8DE63b7253E482622184894",
  },
  {
    chainId: 1,
    address: "0x3D26dcd840fCC8e4B2193AcE8A092e4a65832F9f",
  },
  {
    chainId: 1,
    address: "0x391E86e2C002C70dEe155eAceB88F7A3c38f5976",
  },
  {
    chainId: 1,
    address: "0x2365a4890eD8965E564B7E2D27C38Ba67Fec4C6F",
  },
  {
    chainId: 1,
    address: "0x5394794Be8b6eD5572FCd6b27103F46b5F390E8f",
  },
  {
    chainId: 1,
    address: "0x358bD0d980E031E23ebA9AA793926857703783BD",
  },
  {
    chainId: 1,
    address: "0xd109b2A304587569c84308c55465cd9fF0317bFB",
  },
  {
    chainId: 1,
    address: "0xd145c6ae8931ed5Bca9b5f5B7dA5991F5aB63B5c",
  },
  {
    chainId: 1,
    address: "0xCa5DFDABBfFD58cfD49A9f78Ca52eC8e0591a3C5",
  },
  {
    chainId: 1,
    address: "0x3Ed3B47Dd13EC9a98b44e6204A523E766B225811",
  },
  {
    chainId: 1,
    address: "0x9ff58f4fFB29fA2266Ab25e75e2A8b3503311656",
  },
  {
    chainId: 1,
    address: "0x030bA81f1c18d280636F32af80b9AAd02Cf0854e",
  },
  {
    chainId: 1,
    address: "0x5165d24277cD063F5ac44Efd447B27025e888f37",
  },
  {
    chainId: 1,
    address: "0xDf7FF54aAcAcbFf42dfe29DD6144A69b629f8C9e",
  },
  {
    chainId: 1,
    address: "0xB9D7CB55f463405CDfBe4E90a6D2Df01C2B92BF1",
  },
  {
    chainId: 1,
    address: "0xFFC97d72E13E01096502Cb8Eb52dEe56f74DAD7B",
  },
  {
    chainId: 1,
    address: "0x05Ec93c0365baAeAbF7AefFb0972ea7ECdD39CF1",
  },
  {
    chainId: 1,
    address: "0xA361718326c15715591c299427c62086F69923D9",
  },
  {
    chainId: 1,
    address: "0x028171bCA77440897B824Ca71D1c56caC55b68A3",
  },
  {
    chainId: 1,
    address: "0xaC6Df26a590F08dcC95D5a4705ae8abbc88509Ef",
  },
  {
    chainId: 1,
    address: "0x39C6b3e42d6A679d7D776778Fe880BC9487C2EDA",
  },
  {
    chainId: 1,
    address: "0xa06bC25B5805d5F8d82847D191Cb4Af5A3e873E0",
  },
  {
    chainId: 1,
    address: "0xa685a61171bb30d4072B338c80Cb7b2c865c873E",
  },
  {
    chainId: 1,
    address: "0xc713e5E149D5D0715DcD1c156a020976e7E56B88",
  },
  {
    chainId: 1,
    address: "0xCC12AbE4ff81c9378D670De1b57F8e0Dd228D77a",
  },
  {
    chainId: 1,
    address: "0x35f6B052C598d933D69A4EEC4D04c73A191fE6c2",
  },
  {
    chainId: 1,
    address: "0x6C5024Cd4F8A59110119C56f8933403A539555EB",
  },
  {
    chainId: 1,
    address: "0x101cc05f4A51C0319f570d5E146a8C625198e636",
  },
  {
    chainId: 1,
    address: "0xBcca60bB61934080951369a648Fb03DF4F96263C",
  },
  {
    chainId: 1,
    address: "0x8dAE6Cb04688C62d939ed9B68d32Bc62e49970b1",
  },
  {
    chainId: 1,
    address: "0xD37EE7e4f452C6638c96536e68090De8cBcdb583",
  },
  {
    chainId: 1,
    address: "0x272F97b7a56a387aE942350bBC7Df5700f8a4576",
  },
  {
    chainId: 1,
    address: "0xF256CC7847E919FAc9B808cC216cAc87CCF2f47a",
  },
  {
    chainId: 1,
    address: "0x514cd6756CCBe28772d4Cb81bC3156BA9d1744aa",
  },
  {
    chainId: 1,
    address: "0xc9BC48c72154ef3e5425641a3c747242112a46AF",
  },
  {
    chainId: 1,
    address: "0x1E6bb68Acec8fefBD87D192bE09bb274170a0548",
  },
  {
    chainId: 1,
    address: "0x2e8F4bdbE3d47d7d7DE490437AeA9915D930F1A3",
  },
  {
    chainId: 1,
    address: "0x6F634c6135D2EBD550000ac92F494F9CB8183dAe",
  },
  {
    chainId: 1,
    address: "0xd4937682df3C8aEF4FE912A96A74121C0829E664",
  },
  {
    chainId: 1,
    address: "0x683923dB55Fead99A79Fa01A27EeC3cB19679cC3",
  },
  {
    chainId: 1,
    address: "0x1982b2F5814301d4e9a8b0201555376e62F82428",
  },
  {
    chainId: 1,
    address: "0x9a14e23A58edf4EFDcB360f68cd1b95ce2081a2F",
  },
  {
    chainId: 1,
    address: "0xc2e2152647F4C26028482Efaf64b2Aa28779EFC4",
  },
  {
    chainId: 1,
    address: "0x952749E07d7157bb9644A894dFAF3Bad5eF6D918",
  },
  {
    chainId: 1,
    address: "0xB29130CBcC3F791f077eAdE0266168E808E5151e",
  },
  {
    chainId: 1,
    address: "0xce1871f791548600cb59efbefFC9c38719142079",
  },
  {
    chainId: 137,
    address: "0x27F8D03b3a2196956ED754baDc28D73be8830A6e",
  },
  {
    chainId: 137,
    address: "0x1a13F4Ca1d028320A707D99520AbFefca3998b7F",
  },
  {
    chainId: 137,
    address: "0x60D55F02A771d515e077c9C2403a1ef324885CeC",
  },
  {
    chainId: 137,
    address: "0x5c2ed810328349100A66B82b78a1791B101C9D61",
  },
  {
    chainId: 137,
    address: "0x28424507fefb6f7f8E9D3860F56504E4e5f5f390",
  },
  {
    chainId: 137,
    address: "0x8dF3aad3a84da6b69A4DA8aeC3eA40d9091B2Ac4",
  },
  {
    chainId: 137,
    address: "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
  },
  {
    chainId: 137,
    address: "0x080b5BF8f360F624628E0fb961F4e67c9e3c7CF1",
  },
  {
    chainId: 137,
    address: "0xc4195D4060DaEac44058Ed668AA5EfEc50D77ff6",
  },
  {
    chainId: 137,
    address: "0x81fB82aAcB4aBE262fc57F06fD4c1d2De347D7B1",
  },
  {
    chainId: 137,
    address: "0x3Df8f92b7E798820ddcCA2EBEA7BAbda2c90c4aD",
  },
  {
    chainId: 137,
    address: "0x21eC9431B5B55c5339Eb1AE7582763087F98FAc2",
  },
  {
    chainId: 137,
    address: "0x0Ca2e42e8c21954af73Bc9af1213E4e81D6a669A",
  },
  {
    chainId: 43114,
    address: "0x53f7c5869a859F0AeC3D334ee8B4Cf01E3492f21",
  },
  {
    chainId: 43114,
    address: "0x47AFa96Cdc9fAb46904A55a6ad4bf6660B53c38a",
  },
  {
    chainId: 43114,
    address: "0x532E6537FEA298397212F09A61e03311686f548e",
  },
  {
    chainId: 43114,
    address: "0x46A51127C3ce23fb7AB1DE06226147F446e4a857",
  },
  {
    chainId: 43114,
    address: "0xD45B7c061016102f9FA220502908f2c0f1add1D7",
  },
  {
    chainId: 43114,
    address: "0x686bEF2417b6Dc32C50a3cBfbCC3bb60E1e9a15D",
  },
  {
    chainId: 43114,
    address: "0xDFE521292EcE2A4f44242efBcD66Bc594CA9714B",
  },
  {
    chainId: 1,
    address: "0x4d5F47FA6A74757f35C14fD3a6Ef8E3C9BC514E8",
  },
  {
    chainId: 1,
    address: "0x0B925eD163218f6662a35e0f0371Ac234f9E9371",
  },
  {
    chainId: 1,
    address: "0x5Ee5bf7ae06D1Be5997A1A72006FE6C607eC6DE8",
  },
  {
    chainId: 1,
    address: "0x98C23E9d8f34FEFb1B7BD6a91B7FF122F4e16F5c",
  },
  {
    chainId: 1,
    address: "0x018008bfb33d285247A21d44E50697654f754e63",
  },
  {
    chainId: 1,
    address: "0x5E8C8A7243651DB1384C0dDfDbE39761E8e7E51a",
  },
  {
    chainId: 1,
    address: "0xA700b4eB416Be35b2911fd5Dee80678ff64fF6C9",
  },
  {
    chainId: 1,
    address: "0x977b6fc5dE62598B08C85AC8Cf2b745874E8b78c",
  },
  {
    chainId: 1,
    address: "0x23878914EFE38d27C4D67Ab83ed1b93A74D4086a",
  },
  {
    chainId: 1,
    address: "0xCc9EE9483f662091a1de4795249E24aC0aC2630f",
  },
  {
    chainId: 1,
    address: "0x3Fe6a295459FAe07DF8A0ceCC36F37160FE86AA9",
  },
  {
    chainId: 1,
    address: "0x7B95Ec873268a6BFC6427e7a28e396Db9D0ebc65",
  },
  {
    chainId: 1,
    address: "0x8A458A9dc9048e005d22849F470891b840296619",
  },
  {
    chainId: 1,
    address: "0xC7B4c17861357B8ABB91F25581E7263E08DCB59c",
  },
  {
    chainId: 1,
    address: "0x2516E7B3F76294e03C42AA4c5b5b4DCE9C436fB8",
  },
  {
    chainId: 1,
    address: "0xF6D2224916DDFbbab6e6bd0D1B7034f4Ae0CaB18",
  },
  {
    chainId: 1,
    address: "0x9A44fd41566876A39655f74971a3A6eA0a17a454",
  },
  {
    chainId: 1,
    address: "0x545bD6c032eFdde65A377A6719DEF2796C8E0f2e",
  },
  {
    chainId: 1,
    address: "0x71Aef7b30728b9BB371578f36c5A1f1502a5723e",
  },
  {
    chainId: 1,
    address: "0xd4e245848d6E1220DBE62e155d89fa327E43CB06",
  },
  {
    chainId: 1,
    address: "0x00907f9921424583e7ffBfEdf84F92B7B2Be4977",
  },
  {
    chainId: 1,
    address: "0xB76CF92076adBF1D9C39294FA8e7A67579FDe357",
  },
  {
    chainId: 1,
    address: "0x4C612E3B15b96Ff9A6faED838F8d07d479a8dD4c",
  },
  {
    chainId: 1,
    address: "0x1bA9843bD4327c6c77011406dE5fA8749F7E3479",
  },
  {
    chainId: 1,
    address: "0x5b502e3796385E1e9755d7043B9C945C3aCCeC9C",
  },
  {
    chainId: 1,
    address: "0x82F9c5ad306BBa1AD0De49bB5FA6F01bf61085ef",
  },
  {
    chainId: 1,
    address: "0xb82fa9f31612989525992FCfBB09AB22Eff5c85A",
  },
  {
    chainId: 1,
    address: "0x0C0d01AbF3e6aDfcA0989eBbA9d6e85dD58EaB1E",
  },
  {
    chainId: 1,
    address: "0xBdfa7b7893081B35Fb54027489e2Bc7A38275129",
  },
  {
    chainId: 1,
    address: "0x927709711794F3De5DdBF1D176bEE2D55Ba13c21",
  },
  {
    chainId: 1,
    address: "0x4F5923Fc5FD4a93352581b38B7cD26943012DECF",
  },
  {
    chainId: 137,
    address: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
  },
  {
    chainId: 137,
    address: "0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530",
  },
  {
    chainId: 137,
    address: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
  },
  {
    chainId: 137,
    address: "0x078f358208685046a11C85e8ad32895DED33A249",
  },
  {
    chainId: 137,
    address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
  },
  {
    chainId: 137,
    address: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
  },
  {
    chainId: 137,
    address: "0xf329e36C7bF6E5E86ce2150875a84Ce77f477375",
  },
  {
    chainId: 137,
    address: "0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97",
  },
  {
    chainId: 137,
    address: "0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf",
  },
  {
    chainId: 137,
    address: "0xc45A479877e1e9Dfe9FcD4056c699575a1045dAA",
  },
  {
    chainId: 137,
    address: "0x8Eb270e296023E9D92081fdF967dDd7878724424",
  },
  {
    chainId: 137,
    address: "0x8ffDf2DE812095b1D19CB146E4c004587C0A0692",
  },
  {
    chainId: 137,
    address: "0x724dc807b04555b71ed48a6896b6F41593b8C637",
  },
  {
    chainId: 137,
    address: "0x38d693cE1dF5AaDF7bC62595A37D667aD57922e5",
  },
  {
    chainId: 137,
    address: "0x6533afac2E7BCCB20dca161449A13A32D391fb00",
  },
  {
    chainId: 137,
    address: "0x8437d7C167dFB82ED4Cb79CD44B7a32A1dd95c77",
  },
  {
    chainId: 137,
    address: "0xeBe517846d0F36eCEd99C735cbF6131e1fEB775D",
  },
  {
    chainId: 137,
    address: "0xEA1132120ddcDDA2F119e99Fa7A27a0d036F7Ac9",
  },
  {
    chainId: 137,
    address: "0x80cA0d8C38d2e2BcbaB66aA1648Bd1C7160500FE",
  },
  {
    chainId: 137,
    address: "0xf59036CAEBeA7dC4b86638DFA2E3C97dA9FcCd40",
  },
  {
    chainId: 137,
    address: "0xA4D94019934D8333Ef880ABFFbF2FDd611C762BD",
  },
  {
    chainId: 43114,
    address: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
  },
  {
    chainId: 43114,
    address: "0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530",
  },
  {
    chainId: 43114,
    address: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
  },
  {
    chainId: 43114,
    address: "0x078f358208685046a11C85e8ad32895DED33A249",
  },
  {
    chainId: 43114,
    address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
  },
  {
    chainId: 43114,
    address: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
  },
  {
    chainId: 43114,
    address: "0xf329e36C7bF6E5E86ce2150875a84Ce77f477375",
  },
  {
    chainId: 43114,
    address: "0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97",
  },
  {
    chainId: 43114,
    address: "0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf",
  },
  {
    chainId: 43114,
    address: "0xc45A479877e1e9Dfe9FcD4056c699575a1045dAA",
  },
  {
    chainId: 43114,
    address: "0x8Eb270e296023E9D92081fdF967dDd7878724424",
  },
  {
    chainId: 43114,
    address: "0x8ffDf2DE812095b1D19CB146E4c004587C0A0692",
  },
  {
    chainId: 8453,
    address: "0xD4a0e0b9149BCee3C920d2E00b5dE09138fd8bb7",
  },
  {
    chainId: 8453,
    address: "0xcf3D55c10DB69f28fD1A75Bd73f3D8A2d9c595ad",
  },
  {
    chainId: 8453,
    address: "0x0a1d576f3eFeF75b330424287a95A366e8281D54",
  },
  {
    chainId: 8453,
    address: "0x99CBC45ea5bb7eF3a5BC08FB1B7E56bB2442Ef0D",
  },
  {
    chainId: 8453,
    address: "0x4e65fE4DbA92790696d040ac24Aa414708F5c0AB",
  },
  {
    chainId: 8453,
    address: "0x7C307e128efA31F540F2E2d976C995E0B65F51F6",
  },
  {
    chainId: 1088,
    address: "0x85ABAdDcae06efee2CB5F75f33b6471759eFDE24",
  },
  {
    chainId: 1088,
    address: "0x7314Ef2CA509490f65F52CC8FC9E0675C66390b8",
  },
  {
    chainId: 1088,
    address: "0x885C8AEC5867571582545F894A5906971dB9bf27",
  },
  {
    chainId: 1088,
    address: "0xd9fa75D14c26720d5ce7eE2530793a823e8f07b9",
  },
  {
    chainId: 1088,
    address: "0x8acAe35059C9aE27709028fF6689386a44c09f3a",
  },
  {
    chainId: 100,
    address: "0xa818F1B57c201E092C4A2017A91815034326Efd1",
  },
  {
    chainId: 100,
    address: "0x23e4E76D01B2002BE436CE8d6044b0aA2f68B68a",
  },
  {
    chainId: 100,
    address: "0xA1Fa064A85266E2Ca82DEe5C5CcEC84DF445760e",
  },
  {
    chainId: 100,
    address: "0xc6B7AcA6DE8a6044E0e32d0c841a89244A10D284",
  },
  {
    chainId: 100,
    address: "0xd0Dd6cEF72143E22cCED4867eb0d5F2328715533",
  },
  {
    chainId: 100,
    address: "0xEdBC7449a9b594CA4E053D9737EC5Dc4CbCcBfb2",
  },
  {
    chainId: 100,
    address: "0x7a5c3860a77a8DC1b225BD46d0fb2ac1C6D191BC",
  },
  {
    chainId: 56,
    address: "0x4199CC1F5ed0d796563d7CcB2e036253E2C18281",
  },
  {
    chainId: 56,
    address: "0x9B00a09492a626678E5A3009982191586C444Df9",
  },
  {
    chainId: 56,
    address: "0x56a7ddc4e848EbF43845854205ad71D5D5F72d3D",
  },
  {
    chainId: 56,
    address: "0x2E94171493fAbE316b6205f1585779C887771E2F",
  },
  {
    chainId: 56,
    address: "0x00901a076785e0906d1028c7d6372d247bec7d61",
  },
  {
    chainId: 56,
    address: "0xa9251ca9DE909CB71783723713B21E4233fbf1B1",
  },
  {
    chainId: 56,
    address: "0x75bd1A659bdC62e4C313950d44A2416faB43E785",
  },
  {
    chainId: 42161,
    address: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
  },
  {
    chainId: 42161,
    address: "0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530",
  },
  {
    chainId: 42161,
    address: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
  },
  {
    chainId: 42161,
    address: "0x078f358208685046a11C85e8ad32895DED33A249",
  },
  {
    chainId: 42161,
    address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
  },
  {
    chainId: 42161,
    address: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
  },
  {
    chainId: 42161,
    address: "0xf329e36C7bF6E5E86ce2150875a84Ce77f477375",
  },
  {
    chainId: 42161,
    address: "0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97",
  },
  {
    chainId: 42161,
    address: "0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf",
  },
  {
    chainId: 42161,
    address: "0xc45A479877e1e9Dfe9FcD4056c699575a1045dAA",
  },
  {
    chainId: 42161,
    address: "0x8Eb270e296023E9D92081fdF967dDd7878724424",
  },
  {
    chainId: 42161,
    address: "0x8ffDf2DE812095b1D19CB146E4c004587C0A0692",
  },
  {
    chainId: 42161,
    address: "0x724dc807b04555b71ed48a6896b6F41593b8C637",
  },
  {
    chainId: 42161,
    address: "0x38d693cE1dF5AaDF7bC62595A37D667aD57922e5",
  },
  {
    chainId: 42161,
    address: "0x6533afac2E7BCCB20dca161449A13A32D391fb00",
  },
  {
    chainId: 42161,
    address: "0x8437d7C167dFB82ED4Cb79CD44B7a32A1dd95c77",
  },
  {
    chainId: 10,
    address: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
  },
  {
    chainId: 10,
    address: "0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530",
  },
  {
    chainId: 10,
    address: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
  },
  {
    chainId: 10,
    address: "0x078f358208685046a11C85e8ad32895DED33A249",
  },
  {
    chainId: 10,
    address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
  },
  {
    chainId: 10,
    address: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
  },
  {
    chainId: 10,
    address: "0xf329e36C7bF6E5E86ce2150875a84Ce77f477375",
  },
  {
    chainId: 10,
    address: "0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97",
  },
  {
    chainId: 10,
    address: "0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf",
  },
  {
    chainId: 10,
    address: "0xc45A479877e1e9Dfe9FcD4056c699575a1045dAA",
  },
  {
    chainId: 10,
    address: "0x8Eb270e296023E9D92081fdF967dDd7878724424",
  },
  {
    chainId: 10,
    address: "0x8ffDf2DE812095b1D19CB146E4c004587C0A0692",
  },
  {
    chainId: 10,
    address: "0x724dc807b04555b71ed48a6896b6F41593b8C637",
  },
  {
    chainId: 10,
    address: "0x38d693cE1dF5AaDF7bC62595A37D667aD57922e5",
  },
  {
    chainId: 534352,
    address: "0xf301805bE1Df81102C957f6d4Ce29d2B8c056B2a",
  },
  {
    chainId: 534352,
    address: "0x1D738a3436A8C49CefFbaB7fbF04B660fb528CbD",
  },
  {
    chainId: 534352,
    address: "0x5B1322eeb46240b02e20062b8F0F9908d525B09c",
  },
  {
    chainId: 4002,
    address: "0x2B101eFBB4dFf1fbB8f87f02C560Fb8AC773aFC5",
  },
  {
    chainId: 4002,
    address: "0x7d18f0834A38cA6B0e58CC671900f0017E6d55Ff",
  },
  {
    chainId: 4002,
    address: "0xFFF336d1310D5Ba18CfFb2006afa762EC7d7a4fb",
  },
  {
    chainId: 4002,
    address: "0x17A6c7f20e804a558E0674c0Eef1F69442770596",
  },
  {
    chainId: 4002,
    address: "0x0e426e6e6B226D8bd566e417b90411Dcf14DF861",
  },
  {
    chainId: 4002,
    address: "0x97293798206Fc4FDF23D6b536ED3D694599e35BC",
  },
  {
    chainId: 4002,
    address: "0xf10e04c48f14d50F7378e01a1EEf7498D9a27d08",
  },
  {
    chainId: 4002,
    address: "0xFA3AdA68fA9512B2e22a5fac6b6F0231068A2b36",
  },
  {
    chainId: 4002,
    address: "0x01CEf190E98cE7B8CA722e33C0D3aF456106103d",
  },
  {
    chainId: 4002,
    address: "0xac8fd53423708ac1C49E5486485854FBE1487e65",
  },
  {
    chainId: 250,
    address: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
  },
  {
    chainId: 250,
    address: "0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530",
  },
  {
    chainId: 250,
    address: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
  },
  {
    chainId: 250,
    address: "0x078f358208685046a11C85e8ad32895DED33A249",
  },
  {
    chainId: 250,
    address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
  },
  {
    chainId: 250,
    address: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
  },
  {
    chainId: 250,
    address: "0xf329e36C7bF6E5E86ce2150875a84Ce77f477375",
  },
  {
    chainId: 250,
    address: "0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97",
  },
  {
    chainId: 250,
    address: "0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf",
  },
  {
    chainId: 250,
    address: "0xc45A479877e1e9Dfe9FcD4056c699575a1045dAA",
  },
  {
    chainId: 1666600000,
    address: "0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE",
  },
  {
    chainId: 1666600000,
    address: "0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530",
  },
  {
    chainId: 1666600000,
    address: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
  },
  {
    chainId: 1666600000,
    address: "0x078f358208685046a11C85e8ad32895DED33A249",
  },
  {
    chainId: 1666600000,
    address: "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
  },
  {
    chainId: 1666600000,
    address: "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
  },
  {
    chainId: 1666600000,
    address: "0xf329e36C7bF6E5E86ce2150875a84Ce77f477375",
  },
  {
    chainId: 1666600000,
    address: "0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97",
  },
];
