import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { Props as IconProps } from "../Icon";
import type { ThemeType } from "@sablier/v2-themes";
import type { ComponentProps, ReactNode } from "react";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const Wrapper = styled.div<{ color?: keyof ThemeType["colors"] }>`
  ${(props) => props.theme.styles.row}
  & {
    color: ${(props) => props.theme.colors.gray400};
    column-gap: 4px;
    & > label {
      ${(props) => props.theme.styles.textElement}
      & {
        order: 2;
        &:not(:empty) {
          min-width: 2px;
          &:before {
            content: "";
            display: inline-block;
          }
        }
      }
    }

    ${(props) =>
      props.color &&
      css`
        color: ${props.theme.colors[props.color]} !important;
      `}
  }

  &[data-icon-last="true"] {
    & > *:not(p) {
      order: 3;
    }
  }
`;

export interface Props {
  className?: string;
  color?: keyof ThemeType["colors"];
  forId?: string;
  iconPurpose?: IconProps["purpose"];
  icon?: IconProps["value"];
  isIconLast?: boolean;
  tooltip?: ComponentProps<typeof Tooltip>;
  value?: string | number | ReactNode;
}

function Label({
  className,
  color,
  forId,
  iconPurpose = "heroicon",
  icon,
  isIconLast = false,
  tooltip = {},
  value,
}: Props) {
  return (
    <Tooltip mouseLeaveDelay={0.1} {...tooltip}>
      <Wrapper
        className={className}
        color={color}
        data-component={"label"}
        data-icon-last={isIconLast}
      >
        <label htmlFor={forId}>{value}</label>
        {icon && <Icon purpose={iconPurpose} value={icon} />}
      </Wrapper>
    </Tooltip>
  );
}

export default Label;
