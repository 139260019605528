import styled, { css } from "styled-components";
import type { PropsWithChildren } from "react";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.sizes.zIndexPageBackground};
  width: 100vw;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background};
`;

const Container = styled.div`
  ${(props) => props.theme.styles.contained}
  & {
    position: relative;
    top: 0;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`;

const Light = styled.div<{ horizontal: "left" | "right"; vertical: string }>`
  & {
    position: absolute;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at ${(props) => props.vertical} center,
      ${(props) => props.theme.colors.dark1000} -100%,
      ${(props) => props.theme.colors.background} 30%,
      transparent 30%
    );
    opacity: 0.6;
    filter: blur(60px);
  }

  ${(props) =>
    props.horizontal == "left" &&
    css`
      left: -50%;
    `}
  ${(props) =>
    props.horizontal == "right" &&
    css`
      right: -50%;
    `}
`;

interface Props {
  className?: string;
  left?: "top" | "center" | "bottom" | "none";
  right?: "top" | "center" | "bottom" | "none";
}

function Background({
  children,
  className,
  left = "top",
  right = "top",
}: PropsWithChildren<Props>) {
  return (
    <Wrapper className={className}>
      <Container>
        {left !== "none" && <Light horizontal={"left"} vertical={left} />}
        {right !== "none" && <Light horizontal={"right"} vertical={right} />}
      </Container>
      {children}
    </Wrapper>
  );
}

export default Background;
