import styled from "styled-components";
import { Button } from "@sablier/v2-components/molecules";
import type { ComponentProps } from "react";
import SectionPartial from "../Section";
import Item from "./Item";

const WrapperPartial = styled.div`
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    gap: calc(${(props) => props.theme.sizes.edge} * 2);
    align-items: flex-start;
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 2);
    max-width: 400px;
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex: 1;
    gap: calc(${(props) => props.theme.sizes.edge} * 2);
    justify-content: flex-end;
    align-items: flex-start;
  }
`;

const Section = styled(SectionPartial)`
  & > div {
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
  }
`;

const Actions = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    ${Content} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1);
    }
    ${Left} {
      max-width: 280px;
    }
    ${Right} {
      gap: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }
  ${(props) => props.theme.medias.maxMD} {
    ${Content} {
      ${(props) => props.theme.styles.column}
      & {
        gap: calc(${(props) => props.theme.sizes.edge} * 2);
      }
      ${Left} {
        max-width: 100%;
      }
    }

    ${Right} {
      ${(props) => props.theme.styles.column}
      & {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
        width: 100%;
      }
    }
    ${Actions} {
      width: 100%;
      max-width: 400px;
      & > * {
        &:not(:only-child) {
          flex: 1;
        }
      }
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Right} {
      grid-template-columns: 1fr;
    }
  }
`;

interface Props {
  actions: ComponentProps<typeof Button>[];
  className?: string;
  color?: ComponentProps<typeof Section>["color"];
  description?: string;
  items: ComponentProps<typeof Item>[];
  label: string;
  title: string;
}

function CallToAction({
  actions,
  className,
  color = "primaryMiddle",
  description,
  items,
  label,
  title,
}: Props) {
  return (
    <Wrapper className={className}>
      <Content>
        <Left>
          <Section
            color={color}
            description={description}
            title={title}
            label={label}
          />
          <Actions>
            {actions.map((action, index) => (
              <Button key={index} {...action} />
            ))}
          </Actions>
        </Left>
        <Right>
          {items.map((item) => (
            <Item key={item.title} {...item} />
          ))}
        </Right>
      </Content>
    </Wrapper>
  );
}

export default CallToAction;
