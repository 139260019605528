import Background from "./Background";
import Circle from "./Circle";
import Rectangle from "./Rectangle";

const Decor = {
  Background,
  Circle,
  Rectangle,
};

export default Decor;
