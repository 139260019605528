import styled from "styled-components";
import { Button } from "@sablier/v2-components/molecules";
import { _ } from "@sablier/v2-mixins";
import { Description, Title } from "~/landing/components/atoms";
import type { ComponentProps, PropsWithChildren } from "react";

const WrapperPartial = styled.div`
  position: relative;
  z-index: ${(props) => props.theme.sizes.zIndexOver};
  width: 100%;
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 3);
  }
`;

const Top = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Actions = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex-wrap: wrap;
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    & > * {
      padding: 0;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    ${Container} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 2);
    }
    ${Top} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Container} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 2);
    }
    ${Top} {
      row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    }
  }
`;

interface Props {
  actions?: ComponentProps<typeof Button>[];
  color?: ComponentProps<typeof Title>["color"];
  className?: string;
  description?: ComponentProps<typeof Description>["value"];
  title: ComponentProps<typeof Title>["value"];
  label: ComponentProps<typeof Title>["label"];
}

function Section({
  actions,
  children,
  className,
  color = "primaryMiddle",
  description,
  title,
  label,
}: PropsWithChildren<Props>) {
  return (
    <Wrapper className={className}>
      <Container>
        <Top data-component={"top"}>
          <Title color={color} label={label} value={title} />
          {!_.isNilOrEmptyString(description) ? (
            <Description value={description} />
          ) : (
            false
          )}
          {!_.isNilOrEmptyString(actions) && actions.length ? (
            <Actions>
              {actions.map((action, i) => (
                <Button key={i} {...action} />
              ))}
            </Actions>
          ) : (
            false
          )}
        </Top>
        {children}
      </Container>
    </Wrapper>
  );
}

export default Section;
