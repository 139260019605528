import type { IChain } from "@sablier/v2-types";

const CHAIN_ARBITRUM_ID = 42161;
const CHAIN_AVALANCHE_ID = 43114;
const CHAIN_BASE_ID = 8453;
const CHAIN_BLAST_ID = 81457;
const CHAIN_BSC_ID = 56;
const CHAIN_ETHEREUM_ID = 1;
const CHAIN_GNOSIS_ID = 100;
const CHAIN_IOTEX_ID = 4689;
const CHAIN_LIGHTLINK_ID = 1890;
const CHAIN_LINEA_ID = 59144;
const CHAIN_OPTIMISM_ID = 10;
const CHAIN_POLYGON_ID = 137;
const CHAIN_SCROLL_ID = 534352;
const CHAIN_SEPOLIA_ID = 11155111;
const CHAIN_ZKSYNC_ID = 324;
const CHAIN_ZKSYNC_SEPOLIA_ID = 300;

const _chains: { [key: number]: IChain } = {
  [CHAIN_ARBITRUM_ID]: {
    chainId: CHAIN_ARBITRUM_ID,
    name: "Arbitrum",
    explorer: "https://arbiscan.io",
    rpc: {
      alchemy: (key) => `https://arbitrum-mainnet.g.alchemy.com/v2/${key}`,
      infura: (key) => `https://arbitrum-mainnet.infura.io/v3/${key}`,
      universal: () => `https://arb1.arbitrum.io/rpc`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/8BnGPBojHycDxVo83LP468pUo4xDyCQbtTpHGZXR6SiB`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/CPCMKbUFEM8CzVbfic1y34qKbTrKADX9Du9QxsAMAwqU`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
    trmIdentifier: "arbitrum",
  },
  [CHAIN_AVALANCHE_ID]: {
    chainId: CHAIN_AVALANCHE_ID,
    name: "Avalanche",
    explorer: "https://snowtrace.io",
    rpc: {
      infura: (key) => `https://avalanche-mainnet.infura.io/v3/${key}`,
      universal: () => `https://api.avax.network/ext/bc/C/rpc`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/FdVwZuMV43yCb1nPmjnLQwmzS58wvKuLMPzcZ4UWgWAc`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/2beDuAvmwbyFzJ95HAwfqNjnYT6nEnzbEfSNhfWGMyhJ`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
    trmIdentifier: "avalanche_c_chain",
  },
  [CHAIN_BASE_ID]: {
    chainId: CHAIN_BASE_ID,
    name: "Base",
    explorer: "https://basescan.org",
    rpc: {
      alchemy: (key) => `https://base-mainnet.g.alchemy.com/v2/${key}`,
      universal: () => `https://mainnet.base.org`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/3pxjsW9rbDjmZpoQWzc5CAo4vzcyYE9YQyTghntmnb1K`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/DrfN5cbvcCmpQUSc5RE9T1UxtcnMREi1Rd2PgLzDZCo3`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
  },
  [CHAIN_BLAST_ID]: {
    chainId: CHAIN_BLAST_ID,
    name: "Blast",
    explorer: "https://blastscan.io",
    rpc: {
      alchemy: (key) => `https://blast-mainnet.g.alchemy.com/v2/${key}`,
      universal: () => `https://rpc.blast.io`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/BXoC2ToMZXnTmCjWftQRPh9zMyM7ysijMN54Nxzb2CEY`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/HVqkPvCRAvbxjx6YVmkk6w6rHPrqqtiymcGiQiMKPw8f`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
  },
  [CHAIN_BSC_ID]: {
    chainId: CHAIN_BSC_ID,
    name: "BNB Chain",
    explorer: "https://bscscan.com",
    rpc: {
      universal: () => `https://rpc.ankr.com/bsc`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/BVyi15zcH5eUg5PPKfRDDesezMezh6cAkn8LPvh7MVAF`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/8uU9qAw9fSzdjqebymGRXWCjtZ5DQCmUA6QzRA14ARpz`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
    trmIdentifier: "binance_smart_chain",
  },
  [CHAIN_GNOSIS_ID]: {
    chainId: CHAIN_GNOSIS_ID,
    name: "Gnosis",
    explorer: "https://gnosisscan.io",
    rpc: {
      universal: () => `https://rpc.gnosischain.com`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/EXhNLbhCbsewJPx4jx5tutNXpxwdgng2kmX1J7w1bFyu`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/FViBHgu2TtaZZXspDBzACjuPYKtqVDysmH35pk3W3zJJ`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
  },
  [CHAIN_ETHEREUM_ID]: {
    chainId: CHAIN_ETHEREUM_ID,
    name: "Ethereum",
    explorer: "https://etherscan.io",
    rpc: {
      alchemy: (key) => `https://eth-mainnet.g.alchemy.com/v2/${key}`,
      infura: (key) => `https://mainnet.infura.io/v3/${key}`,
      universal: () => `https://cloudflare-eth.com`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/EuZZnhFtdCGqN2Zt7EMGYDqQKNrVuhJL63KAfwvF35bL`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/FigCYTmdPtXbf4tgNiy5ZrtnYefz92hsMcwM4f9N5ZeZ`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
    trmIdentifier: "ethereum",
  },
  [CHAIN_IOTEX_ID]: {
    chainId: CHAIN_IOTEX_ID,
    name: "IoTeX",
    explorer: "https://iotexscan.io",
    rpc: {
      universal: () => "https://babel-api.mainnet.iotex.io",
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/FkPrCcVwLKVeyQNbP7545fsz6D1hrZh6emxqD6qx68YT`,
          vendor: "the-graph",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/D4u4xkVXmYzs4mdmiqtfsA7B8yNRxjPbZscmrijqk1C1`,
          vendor: "the-graph",
        },
      },
    },
  },
  [CHAIN_LINEA_ID]: {
    chainId: CHAIN_LINEA_ID,
    name: "Linea",
    explorer: "https://lineascan.build/",
    rpc: {
      universal: () => "https://rpc.linea.build",
      infura: (key) => `https://linea-mainnet.infura.io/v3/${key}`,
      alchemy: (key) => `https://linea-mainnet.g.alchemy.com/v2/${key}`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/FoJnatzCZKyp9XjZyUBaw1juTb5ydnFvJvWUxS3oRCHZ`,
          vendor: "the-graph",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/D7T8YEoYGgyufRQ9889kqFSh17AutDA6ohE59fTg7Bdb`,
          vendor: "the-graph",
        },
      },
    },
  },
  [CHAIN_LIGHTLINK_ID]: {
    chainId: CHAIN_LIGHTLINK_ID,
    name: "Lightlink",
    explorer: "https://phoenix.lightlink.io",
    rpc: {
      hosted: () =>
        `https://replicator.phoenix.lightlink.io/rpc/v1/sablier-5b9f6b8c146f0aac62a81370ddbe489b`,
      universal: () => "https://replicator.phoenix.lightlink.io/rpc/v1",
    },
    subgraph: {
      protocol: {
        primary: () =>
          "https://graph.phoenix.lightlink.io/query/subgraphs/name/lightlink/sablier-v2-lightlink",
      },
      airstream: {
        primary: () =>
          "https://graph.phoenix.lightlink.io/query/subgraphs/name/lightlink/sablier-v2-ms-lightlink",
      },
    },
  },
  [CHAIN_OPTIMISM_ID]: {
    chainId: CHAIN_OPTIMISM_ID,
    name: "Optimism",
    explorer: "https://optimistic.etherscan.io",
    rpc: {
      alchemy: (key) => `https://opt-mainnet.g.alchemy.com/v2/${key}`,
      infura: (key) => `https://optimism-mainnet.infura.io/v3/${key}`,
      universal: () => `https://mainnet.optimism.io`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/6e6Dvs1yDpsWDDREZRqxGi54SVdvTNzUdKpKJxniKVrp`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/7iSmF69W4mQqtx6EfWXXn5s27Hrdh72etsPKVC9iE62U`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
    trmIdentifier: "optimism",
  },

  [CHAIN_POLYGON_ID]: {
    chainId: CHAIN_POLYGON_ID,
    name: "Polygon",
    explorer: "https://polygonscan.com",
    rpc: {
      alchemy: (key) => `https://polygon-mainnet.g.alchemy.com/v2/${key}`,
      infura: (key) => `https://polygon-mainnet.infura.io/v3/${key}`,
      universal: () => `https://polygon-rpc.com`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/CsDNYv9XPUMP8vufuwDVKQrVhsxhzzRHezjLFFKZZbrx`,
          vendor: "the-graph",
        },

        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/4r2pC3iyLbzytNa5phat3SWdMEyXG8fmnf1K89D7zP2G`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
    trmIdentifier: "polygon",
  },
  [CHAIN_SCROLL_ID]: {
    chainId: CHAIN_SCROLL_ID,
    name: "Scroll",
    explorer: "https://scrollscan.com/",
    rpc: {
      universal: () => `https://rpc.scroll.io`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/HVcngokCByfveLwguuafrBC34xB65Ne6tpGrXHmqDSrh`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/F1QnrgBpsVKtiVzkLisEC2PDo6cjzLoAy5HhPdFRdjW`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
  },
  [CHAIN_SEPOLIA_ID]: {
    chainId: CHAIN_SEPOLIA_ID,
    name: "Sepolia",
    explorer: "https://sepolia.etherscan.io",
    faucet: "0x776b6fc2ed15d6bb5fc32e0c89de68683118c62a",
    rpc: {
      alchemy: (key) => `https://eth-sepolia.g.alchemy.com/v2/${key}`,
      infura: (key) => `https://sepolia.infura.io/v3/${key}`,
      universal: () => `https://rpc.sepolia.org`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/3JR9ixhdUxX5oc2Yjr6jkG4XUqDd4guy8niL6AYzDKss`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/914AfNqD1J429ZWj9c7vEuizndUrxDF8gJvHmgUP6ZK`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
  },
  [CHAIN_ZKSYNC_ID]: {
    chainId: CHAIN_ZKSYNC_ID,
    name: "zkSync",
    explorer: "https://era.zksync.network",
    rpc: {
      alchemy: (key) => `https://zksync-mainnet.g.alchemy.com/v2/${key}`,
      infura: (key) => `https://zksync-mainnet.infura.io/v3/${key}`,
      universal: () => `https://mainnet.era.zksync.io`,
    },
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/GY2fGozmfZiZ3xF2MfevohLR4YGnyxGxAyxzi9zmU5bY`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/9e37ca4/v1/graphql",
          vendor: "envio",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/BboiKY7JCdznoqurdXRizL9UYD1YdQKajaj4gvUrPPEA`,
          vendor: "the-graph",
        },
        secondary: {
          url: () => "https://indexer.bigdevenergy.link/508d217/v1/graphql",
          vendor: "envio",
        },
      },
    },
  },
  [CHAIN_ZKSYNC_SEPOLIA_ID]: {
    chainId: CHAIN_ZKSYNC_SEPOLIA_ID,
    name: "zkSync Sepolia",
    explorer: "https://sepolia.explorer.zksync.io",
    rpc: {
      universal: () => `https://sepolia.era.zksync.dev`,
    },
    // TO DO update subgraphs after publishing
    subgraph: {
      protocol: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/5FMSMQbTWBrW1CMNSyXpRajCXmJn4hs6Ei3HDbUasDfn`,
          vendor: "the-graph",
        },
      },
      airstream: {
        primary: {
          url: (key) =>
            `https://gateway-arbitrum.network.thegraph.com/api/${key}/subgraphs/id/Ak3e2oELeeh5XYAfHqWAe89sYJeYUXvHgrBbUKHmjaTK`,
          vendor: "the-graph",
        },
      },
    },
  },
} satisfies { [key: number]: IChain };

const chains: {
  [
    key:
      | string
      | "arbitrum"
      | "avalanche"
      | "base"
      | "blast"
      | "bsc"
      | "gnosis"
      | "iotex"
      | "lightlink"
      | "linea"
      | "optimism"
      | "mainnet"
      | "polygon"
      | "scroll"
      | "sepolia"
      | "zksync"
      | "zksyncSepolia"
  ]: IChain;
} = {
  ..._chains,
  arbitrum: _chains[CHAIN_ARBITRUM_ID],
  avalanche: _chains[CHAIN_AVALANCHE_ID],
  base: _chains[CHAIN_BASE_ID],
  blast: _chains[CHAIN_BLAST_ID],
  bsc: _chains[CHAIN_BSC_ID],
  gnosis: _chains[CHAIN_GNOSIS_ID],
  iotex: _chains[CHAIN_IOTEX_ID],
  mainnet: _chains[CHAIN_ETHEREUM_ID],
  lightlink: _chains[CHAIN_LIGHTLINK_ID],
  linea: _chains[CHAIN_LINEA_ID],
  optimism: _chains[CHAIN_OPTIMISM_ID],
  polygon: _chains[CHAIN_POLYGON_ID],
  scroll: _chains[CHAIN_SCROLL_ID],
  sepolia: _chains[CHAIN_SEPOLIA_ID],
  zksync: _chains[CHAIN_ZKSYNC_ID],
  zksyncSepolia: _chains[CHAIN_ZKSYNC_SEPOLIA_ID],
};

export default chains;
