import styled from "styled-components";
import { Label } from "@sablier/v2-components/molecules";
import { _ } from "@sablier/v2-mixins";
import type { ThemeType } from "@sablier/v2-themes";
import type { PropsWithChildren } from "react";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    align-items: flex-start;
    text-align: left;

    *[data-component="label"] {
      label {
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
  }
`;

const Content = styled.div`
  & > h3 {
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;
    font-size: 24pt;
    line-height: 140%;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    gap: calc(${(props) => props.theme.sizes.edge} * 1);
  }
`;

interface Props {
  className?: string;
  color?: keyof ThemeType["colors"];
  isHighlighted?: boolean;
  label?: string;
  value?: string;
}

function Title({
  color = "white",
  label,
  children,
  className,
  isHighlighted = false,
  value,
}: PropsWithChildren<Props>) {
  return (
    <Wrapper className={className} data-highlight={isHighlighted}>
      {!_.isNilOrEmptyString(label) ? (
        <Label color={color} value={label} />
      ) : (
        false
      )}
      <Content>
        {!_.isNilOrEmptyString(value) ? <h3>{value}</h3> : children}
      </Content>
    </Wrapper>
  );
}

export default Title;
