import type { Locale } from "@sablier/v2-locales";

function builder(
  base: string,
  params: { [key: string]: string | string[] | undefined },
) {
  return base.concat("?").concat(
    Object.keys(params)
      .map((item) => {
        if (String(params[item]) === params[item]) {
          return `${item}=${params[item]}`;
        }
        if (Array.isArray(params[item])) {
          return `${item}=${(params[item] as string[]).join(",")}`;
        }
      })
      .filter((item) => !(item === undefined || item === null || item === ""))
      .join("&"),
  );
}

export const pages = {
  airstream: {
    dashboard: {
      locales: "pages.airstream.dashboard" as Locale,
      route: "/airstreams",
      builder: () => "/airstreams",
      title: "Airstreams",
      depth: 2,
    },
    profile: {
      locales: "pages.airstream.profile" as Locale,
      route: "/airstream/[id]",
      builder: (id: string | undefined) => `/airstream/${id}`,
      title: "Airstream",
      depth: 2,
    },
    create: {
      locales: "pages.airstream.create" as Locale,
      route: "/airstream/create",
      builder: (params: { [key: string]: string } = {}) =>
        builder(`/airstream/create`, params),
      title: "Create Airstream Campaign",
      depth: 2,
    },
  },
  createGroup: {
    locales: "pages.createGroup" as Locale,
    route: "/create",
    builder: (params: { shape: string }) => builder("/create", params),
    title: "Create Streams",
    depth: 1,
  },
  createCSV: {
    locales: "pages.createCSV" as Locale,
    route: "/create/csv",
    builder: (params: { shape: string }) => builder("/create/csv", params),
    title: "Create Streams (CSV)",
    depth: 1,
  },
  dashboard: {
    locales: "pages.dashboard" as Locale,
    route: "/",
    builder: () => "/",
    title: "Streams",
    depth: 0,
  },
  gallery: {
    locales: "pages.gallery" as Locale,
    route: "/gallery",
    builder: () => "/gallery",
    title: "Gallery",
    depth: 0,
  },
  profile: {
    locales: "pages.profile" as Locale,
    route: "/stream/[id]",
    builder: (id: string | undefined) => `/stream/${id}`,
    title: "Stream",
    depth: 1,
  },
};

export const api = {
  frame: {
    latest: {
      chain: {
        frog: "/api/frame/latest/:chain/",
        route: "/api/frame/latest/[chain]",
        builder: (chainId: string) => `/api/frame/latest/${chainId}`,
      },
      home: {
        route: "/api/frame/latest/home/",
        builder: () => "/api/frame/latest/home/",
      },
    },
    stream: {
      id: {
        frog: "/api/frame/stream/:id/",
        route: "/api/frame/stream/[id]",
        builder: (id: string) => `/api/frame/stream/${id}`,
      },
    },
  },
  geolocation: {
    route: "/api/geolocation",
    builder: () => "/api/geolocation/",
  },
  meta: {
    query: {
      /** Keeping for backwards compatibility, mirrors /meta/stream/by-id */
      route: "/api/meta/query",
      builder: (id: string) => builder("/api/meta/query", { id }),
    },
    stream: {
      id: {
        route: "/api/meta/stream/by-id",
        builder: (id: string) => builder("/api/meta/stream/by-id", { id }),
      },
      latest: {
        route: "/api/meta/stream/by-latest",
        builder: (chainId: string) =>
          builder("/api/meta/stream/by-latest", { chainId }),
      },
      params: {
        route: "/api/meta/stream/by-params",
        builder: (params: Record<string, string>) =>
          builder("/api/meta/stream/by-params", params),
      },
    },
    latest: {
      home: {
        route: "/api/meta/latest/home",
        builder: (chains: string) =>
          builder("/api/meta/latest/home", { chains }),
      },
    },
  },
  stream: {
    latest: {
      route: "/api/stream/by-latest",
      builder: (chainId: string) =>
        builder("/api/stream/by-latest", { chainId }),
    },
    id: {
      route: "/api/stream/by-id",
      builder: (id: string) => builder("/api/stream/by-id", { id }),
    },
  },
  merkle: {
    claimable: {
      route: "/api/merkle/claimable",
      builder: (chainId: string, address: string) =>
        builder("/api/merkle/claimable", { chainId, address }),
    },
    eligibility: {
      route: "/api/merkle/eligibility",
      builder: (cid: string, address: string) =>
        builder("/api/merkle/eligibility", { cid, address }),
    },
    details: {
      route: "/api/merkle/details",
      builder: (chainId: string, address: string) =>
        builder("/api/merkle/details", { chainId, address }),
    },
    hidden: {
      route: "/api/merkle/hidden",
      builder: (chainId: string) => builder("/api/merkle/hidden/", { chainId }),
    },
    update: {
      route: "/api/merkle/update",
      builder: (chainId: string, address: string) =>
        builder("/api/merkle/update", { chainId, address }),
    },
  },
  recaptcha: {
    route: "/api/captcha",
    builder: (token: string) => builder("/api/captcha", { token }),
  },
  trm: {
    route: "/api/screening",
    builder: () => "/api/screening",
  },
};

export const tabs = {
  airstream: {
    created: {
      identifier: "created",
      route: pages.airstream.dashboard.route,
      builder: () => pages.airstream.dashboard.builder(),
      title: "Created",
      titleShort: "Created",
      params: { identifier: "t" },
    },
    eligible: {
      identifier: "eligible",
      route: pages.airstream.dashboard.route,
      builder: () =>
        builder(pages.airstream.dashboard.builder(), { t: "eligible" }),
      title: "Claimable",
      titleShort: "Claimable",
      params: { identifier: "t" },
    },
    search: {
      identifier: "search",
      route: pages.airstream.dashboard.route,
      builder: (params: {
        /** chain */
        c: string;
        /** asset / token */
        a?: string;
        /** campaign manager / admin */
        m?: string;
        /** ongoing /alive */
        o?: string;
        /** campaign name */
        n?: string;
      }) =>
        builder(pages.airstream.dashboard.builder(), {
          t: "search",
          ...params,
        }),
      title: "Search",
      titleShort: "Search",
      params: {
        identifier: "t",
        chainId: "c",
        token: "a",
        admin: "m",
        isAlive: "o",
        name: "n",
      } as const,
    },
  },
  dashboard: {
    all: {
      identifier: "all",
      route: pages.dashboard.route,
      builder: () => pages.dashboard.builder(),
      title: "All",
      titleShort: "All",
      params: { identifier: "t" },
    },
    recipient: {
      identifier: "recipient",
      route: pages.dashboard.route,
      builder: () => builder(pages.dashboard.builder(), { t: "recipient" }),
      title: "As recipient",
      titleShort: "In",
      params: { identifier: "t" },
    },
    search: {
      identifier: "search",
      route: pages.dashboard.route,
      builder: (params: {
        /** chain */
        c: string;
        /** identifiers */
        i?: string[];
        /** sender */
        s?: string;
        /** recipient */
        r?: string;
        /** asset / token */
        a?: string;
      }) => builder(pages.dashboard.builder(), { t: "search", ...params }),
      title: "Search",
      titleShort: "Search",
      params: {
        identifier: "t",
        chainId: "c",
        ids: "i",
        sender: "s",
        recipient: "r",
        token: "a",
      } as const,
    },
    sender: {
      identifier: "sender",
      route: pages.dashboard.route,
      builder: () => builder(pages.dashboard.builder(), { t: "sender" }),
      title: "As sender",
      titleShort: "Out",
      params: { identifier: "t" },
    },
  },
};
