// Tags needed for Cypress
export const tags = {
  pageRendered: "rendered-page",
};

export const whys = [
  "flexibility",
  "group",
  "nft",
  "permission",
  "audited",
] as const;

export type IWhy = (typeof whys)[number] | "record";
