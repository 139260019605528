import { _ } from "@sablier/v2-mixins";
import type {
  IAddress,
  IEnvironmentVariable,
  ResultCampaignDetails,
  ResultEligibleCampaigns,
  ResultHiddenCampaigns,
  TRMResponse,
  TRMScreeningResponseItem,
  UniswapTokenList,
} from "@sablier/v2-types";
import { blacklist } from "./blacklist";
import links from "./links";
import { RATE_LIMITED_EXCEPTION } from "./macros";
import routes from "./routes";
import services from "./services";

export async function sanctionsScreeningTRM(
  address: string | undefined,
): Promise<Array<TRMResponse>> {
  if (_.isNilOrEmptyString(address)) {
    throw new Error("Missing address.");
  }

  if (blacklist.some((item) => _.toAddress(item) === _.toAddress(address))) {
    return [
      {
        address,
        isSanctioned: true,
      },
    ];
  }
  const headers = new Headers();
  const username = (process.env.NEXT_PUBLIC_TRM_SANCTIONS_API_KEY ||
    "") satisfies IEnvironmentVariable;
  const password = username;

  headers.set("Content-Type", "application/json");
  headers.set(
    "Authorization",
    `Basic ${Buffer.from(username.concat(":").concat(password)).toString(
      "base64",
    )}`,
  );

  const response = await fetch(links.trm_banned_screening_endpoint, {
    credentials: "omit",
    headers,
    method: "POST",
    body: JSON.stringify([{ address }]),
  });

  if (!response.ok) {
    const error = new Error(
      "TRM V1 request failed with status " + response.status,
    );
    throw error;
  }

  return response.json();
}

export async function walletScreeningTRM(
  chain: string,
  addresses: string[],
): Promise<Array<TRMScreeningResponseItem>> {
  if (_.isEmpty(addresses)) {
    throw new Error("Missing addresses.");
  }

  const response = await fetch(routes.client.api.trm.builder(), {
    credentials: "omit",
    method: "POST",
    body: JSON.stringify(addresses.map((address) => ({ address, chain }))),
  });

  if (!response.ok) {
    const error = new Error(
      "TRM Screening API request failed with status " + response.status,
    );
    throw error;
  }

  return response.json();
}

type PropsCreate = {
  query: { decimals: number };
  form: { data: File };
};

type ResultCreate = {
  /** IPFS content identifier for the uploaded file */
  cid: string;
  /** Expected number of recipients */
  recipients: string;
  /** HEX root fo the merkle tree */
  root: string;
  /** Humanized status */
  status: string;
  /** Expected amount of assets required by the campaign */
  total: string;
};

export async function sablierServiceMerkleCreate({
  query,
  form,
}: PropsCreate): Promise<ResultCreate> {
  _.expect(query?.decimals, "decimals");
  _.expect(form?.data, "file (data)");

  const formData = new FormData();
  formData.append("data", form.data);

  const endpoint = new URL(services.merkleAPI.create());
  endpoint.searchParams.append("decimals", query.decimals.toString());

  const response = await fetch(endpoint, {
    method: "POST",
    credentials: "omit",
    body: formData,
  });

  if (response.ok) {
    return response.json();
  }

  const message = await response.json();
  const extracted = _.get(message, "errors.0.message");
  const rows = _.toArray(_.get(message, "errors") || [])
    .map((item) => (_.has(item, "row") ? _.get(item, "row") : undefined))
    .filter((i) => i)
    .slice(0, 20);

  if (!_.isNilOrEmptyString(extracted)) {
    if (!_.isNilOrEmptyString(rows) || !rows.length) {
      if (rows.length === 1) {
        throw new Error(`${extracted} Error on row: ${rows[0]}`);
      }
      throw new Error(
        `${extracted} Errors starting on rows: ${rows.join(", ")}`,
      );
    }
    throw new Error(extracted);
  }
  throw new Error(message);
}

type PropsEligibility = {
  query: { cid: string; address: IAddress };
};

type ResultEligibility = {
  /** Address of the requested recipient */
  address: IAddress;
  /** Amount the recipient is eligible for */
  amount: string;
  /** Position of the recipient in the list */
  index: 0;
  /** Merkle proof */
  proof: string[];
};

type PropsEligibleCampaigns = {
  query: { chainId: number; address: IAddress };
};

type PropsHiddenCampaigns = {
  query: { chainId: number };
};

export async function sablierServiceMerkleEligibility({
  query,
}: PropsEligibility): Promise<ResultEligibility> {
  _.expect(query?.cid, "CID");
  _.expect(query?.address, "address");

  const response = await fetch(
    routes.client.api.merkle.eligibility.builder(
      query.cid.toString(),
      query.address.toString(),
    ),
    {
      credentials: "omit",
      method: "GET",
    },
  );

  if (response.ok) {
    return response.json();
  }

  if (response.status === 429) {
    throw new Error(RATE_LIMITED_EXCEPTION);
  }

  const result = await response.json();
  throw new Error(_.get(result, "message"));
}

type PropsReCaptcha = {
  query: { token: string | null };
};

type ResultReCaptcha = {
  success: boolean;
};

export async function checkReCaptcha({
  query,
}: PropsReCaptcha): Promise<ResultReCaptcha> {
  _.expect(query?.token, "token");

  const response = await fetch(
    routes.client.api.recaptcha.builder(query.token?.toString() ?? ""),
    {
      credentials: "omit",
      method: "POST",
    },
  );

  if (response.ok) {
    return response.json();
  }

  const result = await response.json();
  throw new Error(_.get(result, "message"));
}

type PropsValidity = {
  query: { cid: string };
};

type ResultValidity = {
  /** IPFS content identifier for the uploaded file */
  cid: string;
  /** Expected number of recipients */
  recipients: string;
  /** HEX root fo the merkle tree */
  root: string;
  /** Humanized status */
  status: string;
  /** Expected amount of assets required by the campaign */
  total: string;
};

export async function sablierServiceMerkleValidity({
  query,
}: PropsValidity): Promise<ResultValidity> {
  _.expect(query?.cid, "CID");

  const endpoint = new URL(services.merkleAPI.validity());
  endpoint.searchParams.append("cid", query.cid.toString());

  const response = await fetch(endpoint, {
    credentials: "omit",
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  }

  const result = await response.json();
  throw new Error(_.get(result, "message"));
}

export async function tokenList({
  url,
}: {
  url: string | undefined;
}): Promise<UniswapTokenList> {
  try {
    if (_.isNilOrEmptyString(url)) {
      throw new Error("Missing list url.");
    }
    const response = await fetch(url, {
      credentials: "omit",
      method: "GET",
    });

    if (response.ok) {
      return response.json();
    } else {
      const error = await _.attemptAsync(async () => response.json());
      if (_.isError(error) && _.has(error, "message")) {
        throw new Error(error.message);
      }
      throw new Error(`Connection error ${response.status}`);
    }
  } catch (error) {
    throw new Error(`Token list failed to be fetched and/or validated: ${url}`);
  }
}

export async function claimableCampaignCIDs({
  query,
}: PropsEligibleCampaigns): Promise<ResultEligibleCampaigns> {
  _.expect(query?.chainId, "chainId");
  _.expect(query?.address, "address");

  const response = await fetch(
    routes.client.api.merkle.claimable.builder(
      query.chainId.toString(),
      query.address.toString(),
    ),
    {
      credentials: "omit",
      method: "GET",
    },
  );

  if (response.ok) {
    return response.json();
  }

  const result = await response.json();
  throw new Error(_.get(result, "message"));
}

export async function hiddenCampaigns({
  query,
}: PropsHiddenCampaigns): Promise<ResultHiddenCampaigns> {
  _.expect(query?.chainId, "chainId");

  const response = await fetch(
    routes.client.api.merkle.hidden.builder(query.chainId.toString()),
    {
      credentials: "omit",
      method: "GET",
    },
  );

  if (response.ok) {
    return response.json();
  }

  const result = await response.json();
  throw new Error(_.get(result, "message"));
}

export async function campaignDetails({
  query,
}: PropsEligibleCampaigns): Promise<ResultCampaignDetails | null> {
  _.expect(query?.chainId, "chainId");
  _.expect(query?.address, "address");

  const response = await fetch(
    routes.client.api.merkle.details.builder(
      query.chainId.toString(),
      query.address.toString(),
    ),
    {
      credentials: "omit",
      method: "GET",
    },
  );

  if (response.status === 404) {
    return null;
  }

  if (response.ok) {
    return response.json();
  }

  const result = await response.json();
  throw new Error(_.get(result, "message"));
}

type PropsUpdateCampaign = {
  query: { chainId: number; address: IAddress };
  form: {
    hide: boolean;
    geoblock: string[];
    signature: string;
    eligibilityLink?: string;
  };
};

type ResultUpdateCampaign = {
  address: string;
  chainId: number;
};

export async function sablierUpdateCampaign({
  query,
  form,
}: PropsUpdateCampaign): Promise<ResultUpdateCampaign> {
  _.expect(query?.chainId, "chainId");
  _.expect(query?.address, "address");
  _.expect(form?.hide, "hide");
  _.expect(form?.geoblock, "geoblock");
  _.expect(form?.signature, "signature");

  const response = await fetch(
    routes.client.api.merkle.update.builder(
      query.chainId.toString(),
      query.address.toString(),
    ),
    {
      method: "PATCH",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    },
  );

  if (response.ok) {
    return response.json();
  }

  const result = await response.json();
  throw new Error(_.get(result, "message"));
}
