export const pages = {
  home: {
    locales: "pages.home",
    route: "/",
    builder: () => "/",
    depth: 0,
  },
  organizations: {
    locales: "pages.organizations",
    route: "/organizations",
    builder: () => "/organizations",
    depth: 0,
  },
};
