import styled from "styled-components";
import { Decor } from "@sablier/v2-components/atoms";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.sizes.zIndexUnder};
  width: 100%;
  height: 100%;
`;

const Background = styled(Decor.Background)`
  background-color: ${(props) => props.theme.colors.dark000} !important;
`;

function Underlay() {
  return (
    <Wrapper>
      <Background left={"bottom"} right={"bottom"} />
    </Wrapper>
  );
}

export default Underlay;
