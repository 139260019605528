import styled, { keyframes } from "styled-components";
import AssetWalletBlue from "@sablier/v2-assets/3d/wallet-blue-400.png";
import AssetWalletOrange from "@sablier/v2-assets/3d/wallet-orange.png";
import { External } from "@sablier/v2-components/atoms";
import { links } from "@sablier/v2-constants";
import { pages } from "@sablier/v2-constants/routes/landing";
import { useT } from "@sablier/v2-locales";
import { Trans } from "@sablier/v2-locales";
import Image from "next/image";
import { Description as DescriptionPartial } from "~/landing/components/atoms";
import { Section as SectionPartial } from "~/landing/components/organisms";
import Underlay from "./Underlay";

const WrapperPartial = styled.div`
  position: relative;
  width: 100%;
`;

const Anchor = styled.div`
  position: absolute;
  top: -200px;
  z-index: ${(props) => props.theme.sizes.zIndexUnder};
  width: 100px;
  height: 100px;
  pointer-events: none;
`;

const Section = styled(SectionPartial)`
  z-index: ${(props) => props.theme.sizes.zIndexOver};
  & > * {
    gap: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Content = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    align-items: flex-start;
    width: 100%;
  }
`;

const Description = styled(DescriptionPartial)`
  a {
    text-decoration: underline;
  }
`;

const Left = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    flex: 1;
  }
`;

const Right = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    width: 300px;
    height: 100px;
  }
`;

const rightLeftKeyframes = keyframes`
  0%{
    transform: translate3D(0px, 0px, 0px) rotate(0);
  }
  50%{
    transform: translate3D(-20px, 10px, 0px) rotate(-5deg);
  }
  100%{
    transform: translate3D(0px, 0px, 0px) rotate(0);
  }
`;

const Wallet = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: absolute;
    top: -140px;
    right: 120px;
    justify-content: center;
    width: 250px;
    height: 250px;
    margin: 0 auto;

    will-change: transform;
    animation-name: ${rightLeftKeyframes};
    animation-duration: 8000ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;

    img {
      object-fit: contain;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  &[data-page="organizations"] {
    padding-top: 0;
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 2);

    ${Wallet} > img {
      filter: drop-shadow(
        0px 10px 150px ${(props) => props.theme.colors.dark600}
      );
    }
  }

  ${(props) => props.theme.medias.maxLG} {
    &[data-page="home"] {
      ${Section} {
        div[data-component="top"] {
          align-items: center;
          width: 100%;
          * {
            align-items: center;
            text-align: center;
          }
        }
      }
      ${Left} {
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      ${Description} {
        & > div > p {
          text-align: center;
        }
      }
    }
    ${Right} {
      display: none;
    }
  }
`;

function Pricing({ page = "home" }: { page?: keyof typeof pages }) {
  const { t } = useT();

  return (
    <Wrapper data-page={page}>
      <Anchor id={"pricing"} />
      <Section
        color={page === "home" ? "primaryMiddle" : "secondaryDesaturated"}
        label={t("landing.pricing.label")}
        title={t("landing.pricing.title")}
      >
        <Content>
          <Left>
            <Description>
              <p>
                <Trans
                  i18nKey={"landing.pricing.description"}
                  components={[<External key={0} to={links.article.free} />]}
                />
              </p>
            </Description>
          </Left>
          <Right>
            <Wallet>
              <Image
                alt={"Wallet"}
                src={page === "home" ? AssetWalletOrange : AssetWalletBlue}
                fill
                quality={100}
                sizes={
                  "(min-width: 300px) 100vw,(min-width: 1000px) 20vw, 100vw"
                }
              />
            </Wallet>
          </Right>
        </Content>
      </Section>
      <Underlay />
    </Wrapper>
  );
}

export default Pricing;
