import { useMemo } from "react";
import { _ } from "@sablier/v2-mixins";
import type { PropsWithChildren } from "react";

interface Props {
  href?: string;
  target?: string | "_blank";
  to?: string;
  [key: string]: unknown;
}

export default function External({
  children,
  href,
  target = "_blank",
  to,
  ...props
}: PropsWithChildren<Props>) {
  const link = useMemo(() => {
    if (_.isNilOrEmptyString(href) && !_.isNilOrEmptyString(to)) {
      return to;
    }
    return href;
  }, [href, to]);
  return (
    <a {...props} href={link} target={target} rel={"noopener noreferrer"}>
      {children}
    </a>
  );
}
