import { getTheme } from "@sablier/v2-themes";

const theme = getTheme("dark");

export default function FavIcon() {
  return (
    <>
      <link rel={"icon"} href={"/favicon.ico"} />
      <link
        rel={"apple-touch-icon-precomposed"}
        href={" /fav/apple-icon-precomposed.png"}
      />
      <link rel={"apple-touch-icon"} href={" /fav/apple-icon-180x180.png"} />
      <link
        rel={"apple-touch-icon"}
        sizes={"57x57"}
        href={"/fav/apple-icon-57x57.png"}
      />
      <link
        rel={"apple-touch-icon"}
        sizes={"60x60"}
        href={"/fav/apple-icon-60x60.png"}
      />
      <link
        rel={"apple-touch-icon"}
        sizes={"72x72"}
        href={"/fav/apple-icon-72x72.png"}
      />
      <link
        rel={"apple-touch-icon"}
        sizes={"76x76"}
        href={"/fav/apple-icon-76x76.png"}
      />
      <link
        rel={"apple-touch-icon"}
        sizes={"114x114"}
        href={"/fav/apple-icon-114x114.png"}
      />
      <link
        rel={"apple-touch-icon"}
        sizes={"120x120"}
        href={"/fav/apple-icon-120x120.png"}
      />
      <link
        rel={"apple-touch-icon"}
        sizes={"144x144"}
        href={"/fav/apple-icon-144x144.png"}
      />
      <link
        rel={"apple-touch-icon"}
        sizes={"152x152"}
        href={"/fav/apple-icon-152x152.png"}
      />
      <link
        rel={"apple-touch-icon"}
        sizes={"180x180"}
        href={"/fav/apple-icon-180x180.png"}
      />

      <link
        rel={"icon"}
        type={"image/png"}
        sizes={"192x192"}
        href={"/fav/android-icon-192x192.png"}
      />
      <link
        rel={"icon"}
        type={"image/png"}
        sizes={"32x32"}
        href={"/fav/favicon-32x32.png"}
      />
      <link
        rel={"icon"}
        type={"image/png"}
        sizes={"96x96"}
        href={"/fav/favicon-96x96.png"}
      />
      <link
        rel={"icon"}
        type={"image/png"}
        sizes={"16x16"}
        href={"/fav/favicon-16x16.png"}
      />

      <link rel={"manifest"} href={"/manifest.json"} />
      <link rel={"shortcut icon"} href={"/favicon.ico"} />
      <meta
        name={"msapplication-TileColor"}
        content={theme.colors.primaryMiddle}
      />
      <meta
        name={"msapplication-config"}
        content={"/meta/favicon/browserconfig.xml"}
      />
      <meta name={"theme-color"} content={theme.colors.dark100} />
    </>
  );
}
