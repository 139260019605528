import { useEffect, useState } from "react";
import { _ } from "@sablier/v2-mixins";

function getSizes() {
  return {
    height: _.isWindow() ? window.innerHeight : 0,
    width: _.isWindow() ? window.innerWidth : 0,
  };
}

export default function useWindowSize() {
  const [sizes, setSizes] = useState<{ height: number; width: number }>(
    getSizes(),
  );

  /**
   * Effects
   * ---------
   * Measure the height and width  of the parent container to define the size of the box
   */

  useEffect(() => {
    const measure = () => {
      setSizes(getSizes());
    };

    if (_.isWindow()) {
      window.addEventListener("resize", measure);
      measure();
    }

    return () => {
      if (_.isWindow()) {
        window.removeEventListener("resize", measure);
      }
    };
  }, []);

  return sizes;
}
