import { memo, useMemo } from "react";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import NextHead from "next/head";
import type { PropsWithChildren } from "react";
import FavIcon from "./favicon";

interface Props {
  canonical?: (domain: string) => string | undefined;
  description?: string;
  domain?: string;
  image?: (domain: string) => string;
  purpose?: "client" | "landing" | "landingLabs";
  title: string;
  username?: string;
}

function Head({
  children,
  domain = links.v2.client,
  purpose = "client",
  username = "@Sablier",
  ...props
}: PropsWithChildren<Props>) {
  const { t } = useT();

  const title = useMemo(() => {
    const base = "Sablier";

    if (_.isNilOrEmptyString(props.title)) {
      return base;
    }

    if (purpose === "client") {
      return `${props.title} | ${base}`;
    }

    return props.title;
  }, [props.title, purpose]);

  const description = useMemo(() => {
    const base = t(`about.meta.client.description`);

    if (_.isNilOrEmptyString(props.description)) {
      return base;
    }

    return t(`about.meta.${purpose}.description`);
  }, [props.description, purpose, t]);

  const canonical = useMemo(() => {
    if (_.isNil(props.canonical)) {
      return undefined;
    }

    return props.canonical(domain);
  }, [domain, props]);

  const image = useMemo(() => {
    if (_.isNil(props.image)) {
      return new URL(`${domain}/meta/twitter.png`).toString();
    }

    return props.image(domain);
  }, [domain, props]);

  const url = useMemo(() => {
    if (_.isWindow()) {
      return window.location.href;
    }

    return domain;
  }, [domain]);

  return (
    <NextHead>
      <meta charSet={"utf-8"} />

      {/* Title */}
      <title>{title}</title>
      <meta key={"og:title"} property={"og:title"} content={title} />
      <meta key={"twitter:title"} name={"twitter:title"} content={title} />

      {/* Keywords */}
      <meta
        name={"keywords"}
        content={
          "blockchain, DAO, decentralized finance, defi, Ethereum, money streaming, NFT, open source, payments, payroll, Sablier, Safe, smart contracts, solidity, token distribution, token streaming, vesting, web3"
        }
      />

      {/* Description */}
      <meta key={"description"} name={"description"} content={description} />
      <meta
        key={"og:description"}
        property={"og:description"}
        content={description}
      />
      <meta
        key={"twitter:description"}
        name={"twitter:description"}
        content={description}
      />

      {/* Image */}
      <meta key={"twitter:image"} name={"twitter:image"} content={image} />
      <meta key={"og:image"} property={"og:image"} content={image} />
      <meta key={"og:image:alt"} property={"og:image:alt"} content={title} />

      {/* OpenGraph General */}
      <meta key={"og:locale"} property={"og:locale"} content={"en_US"} />
      <meta key={"og:type"} property={"og:type"} content={"website"} />
      <meta key={"og:url"} property={"og:url"} content={url} />
      <meta
        key={"og:site_name"}
        property={"og:site_name"}
        content={"Sablier"}
      />

      {!_.isNilOrEmptyString(canonical) ? (
        <link rel={"canonical"} href={canonical} />
      ) : (
        false
      )}

      {/* Twitter */}
      <meta key={"twitter:url"} property={"twitter:url"} content={url} />
      <meta key={"twitter:site"} name={"twitter:site"} content={username} />
      <meta
        key={"twitter:creator"}
        name={"twitter:creator"}
        content={username}
      />
      <meta
        key={"twitter:card"}
        name={"twitter:card"}
        content={"summary_large_image"}
      />
      <meta
        key={"twitter:domain"}
        property={"twitter:domain"}
        content={domain}
      />
      <meta
        name={"twitter:image:alt"}
        key={"twitter:image:alt"}
        content={title}
      />
      <FavIcon />
      {children}
    </NextHead>
  );
}

const Meta = {
  Head: memo(Head),
};

export default Meta;
