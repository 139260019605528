import { useEffect, useRef } from "react";
import styled from "styled-components";
import Jazzicon from "@metamask/jazzicon";
import { _ } from "@sablier/v2-mixins";

const Container = styled.div<{ size?: number | string }>`
  flex-shrink: 0;
  width: ${(props) => props.size || props.theme.sizes.icon};
  height: ${(props) => props.size || props.theme.sizes.icon};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.dark050};
`;

interface Props {
  address?: string | null;
  size?: number;
}

function Identicon({ address = null, size = 18 }: Props) {
  const reference = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!_.isWindow()) {
      return;
    }
    if (!_.isNil(address) && !_.isNil(_.get(reference, "current"))) {
      reference!.current!.innerHTML = "";
      reference!.current!.appendChild(
        Jazzicon(size, parseInt(address.slice(2, 10), 16)),
      );
    }
  }, [address, size]);

  return <Container size={size && _.toSuffix(size, "px")} ref={reference} />;
}

export default Identicon;
